import { Box, Button, Divider, Grid, Pagination, Paper, Stack, Typography } from "@mui/material";
import { DataGrid, GridCellParams, GridColDef, gridPageCountSelector, gridPageSelector, GridPaginationModel, GridRenderCellParams, GridRowSelectionModel, useGridApiRef, useGridSelector } from "@mui/x-data-grid";
import LoadingCircle from "../../utils/LoadingCircle";
import { useCallback, useEffect, useRef, useState } from "react";
import { CommonUtils } from "../../utils/common_utils";
import { useNavigate } from "react-router-dom";
import { useWindowSize } from "../../utils/useWindowSize";
import dayjs, { Dayjs } from "dayjs";
import { userState } from "../../interface/MainInterface";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { HttpStatisticApi } from "../../interface/statistic-api";
import Toast from "../../utils/Toast";


interface propsType {
  userState: userState
}

const cUtils = new CommonUtils();

const pageSize: number = 10;

const statisticApi = new HttpStatisticApi();

export default function MngStatistics({ userState } : propsType) {
  const gridApi = useGridApiRef();
  const navigate = useNavigate();
  const windowSize = useWindowSize();
  const toastRef: any = useRef();

  const [isLoading, setIsLoading] = useState(false);

  const [gridHeight, setGridHeight] = useState(708);

  const [selectedRow, setSelectedRow] = useState<GridRowSelectionModel>([]);
  const [selectedPage, setSelectedPage] = useState<GridPaginationModel>({ pageSize: 10, page: 0 });

  const [srchDateFrom, setSrchDateFrom] = useState<Dayjs | null>(dayjs().add(-1, "month"));
  const [srchDateTo, setSrchDateTo] = useState<Dayjs | null>(dayjs()); // 오늘날짜
 
  const [statisticsList, setStatisticsList] = useState<any>([]);
  
  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "NO",
      width: 60,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "search_date",
      headerName: "조회일",
      width: 150,
      headerAlign: "center",
      align: "center",
      renderCell: (param: GridRenderCellParams) => {
        return (
          <>
            <span>{dayjs(param.row.search_date).format("YYYY-MM-DD")}</span>
          </>
        );
      },
    },
    {
      field: "total_likes",
      headerName: "기간 내 좋아요 수",
      width: 150,
      headerAlign: "center",
      type: "number",
      align: "center"
    },
    {
      field: "total_views",
      headerName: "기간 내 VIEW 수",
      width: 150,
      headerAlign: "center",
      type: "number",
      align: "center"
    },
    {
      field: "total_watch_time",
      headerName: "기간 내 시청 시간",
      width: 150,
      headerAlign: "center",
      type: "number",
      align: "center",
      renderCell: (param: GridRenderCellParams) => {
        return (
          <>
            <span>{convertWatchTime(param.row.watch_time)}</span>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    if(userState.isPermission !== undefined && userState.isPermission)
      get_statistic_data();

    return () => {
      setStatisticsList([]);
    }
  }, []);

  useEffect(() => {
    setGridHeight(windowSize.height - 303);
  }, [windowSize]);


  const get_statistic_data = async () => {
    setIsLoading(true);

    const param = {
      command: "get_statistics_data",
      search_from: dayjs(srchDateFrom).format("YYYYMMDD"),
      search_to: dayjs(srchDateTo).format("YYYYMMDD"),
      sortForward: true,
      user_id: userState.id
    };

    const res = await statisticApi.post(param);
    if(res.code === "200") {
      setStatisticsList(res.response.statistics_list.map((row: any, index: any) => ({...row, id: index + 1})));
    } else {
      toastRef.current?.toast("" + res.response.error_msg, "error", 3000, { vertical: "top", horizontal: "center" });
    }
    setIsLoading(false);
  }

  const handleSrchDateFromChange = (newValue: Dayjs | null) => {
    setSrchDateFrom(newValue);
  };
  const handleSrchDateToChange = (newValue: Dayjs | null) => {
    setSrchDateTo(newValue);
  };

  const convertWatchTime = (value: number) => {
    const convertTime = cUtils.convertSecToTime(value)
    
    let result = ""
    if(convertTime.hours === "00")
      result = convertTime.minutes + ":" + convertTime.seconds
    else
      result = convertTime.hours + ":" + convertTime.minutes + ":" + convertTime.seconds
    return result
  }

  const evtGridDblClick = useCallback(
    (param: GridCellParams) => {
      const url = `/statisticsDetail/${param.row.search_date}`;
      navigate(url);
    },
    [navigate]
  );

  function CustomPagination() {
    const page = useGridSelector(gridApi, gridPageSelector);
    const pageCount = useGridSelector(gridApi, gridPageCountSelector);
    return (
      <Box className="tenants-list-pagenation">
        <Pagination
          color="primary"
          shape="rounded"
          count={pageCount === 0 ? 1 : pageCount}
          page={page + 1}
          siblingCount={10}
          onChange={(e, v) => gridApi.current.setPage(v - 1)}
        ></Pagination>
      </Box>
    );
  }

  const downloadExcel = () => {
    let header: any = [
      [
        "NO",
        "조회일",
        `기간 내 좋아요 수(${dayjs(srchDateFrom).format("YYYYMMDD")} ~ ${dayjs(srchDateTo).format("YYYYMMDD")})`,
        `기간 내 VIEW 수(${dayjs(srchDateFrom).format("YYYYMMDD")} ~ ${dayjs(srchDateTo).format("YYYYMMDD")})`,
        `기간 내 시청 시간(${dayjs(srchDateFrom).format("YYYYMMDD")} ~ ${dayjs(srchDateTo).format("YYYYMMDD")})`
      ]
    ];
    let index = 1;
    for (const data of statisticsList) {
      const detail = [
        index,
        dayjs(data.search_date, "YYYYMMDD").format("YYYY-MM-DD"),
        data.total_likes,
        data.total_views,
        convertWatchTime(data.watch_time)
      ];
      header = [...header, detail];
      index += 1;
    }
    const colWidth = [
      { wpx: 40 },
      { wpx: 120 },
      { wpx: 240 },
      { wpx: 240 },
      { wpx: 240 },
    ];
    const sheetName = "통계내역";

    const fileName = dayjs().format("YYYY-MM-DD HHmm") + "_통계 리스트_.xlsx"
    cUtils.downloadExcel(header, colWidth, sheetName, fileName);
  };

  return (
    <>
      <Box sx={{ p: 0, height: "100%"}}>
        <Stack direction={"column"} spacing={0}>
          <Box className="my-account-header-root">
            <Typography variant="h5" gutterBottom className="my-account-header-title">
              세부 통계
            </Typography>
          </Box>
          <Divider />
          <Grid container sx={{ px: "48px", py: "12px", width: "100%"}}>
            <Grid item xs={12}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Paper sx={{ p: 2}} elevation={5}>
                  <Stack spacing={2} direction="row" display="flex" alignItems="center">
                    <Grid item xs={8}>
                      <Stack direction={{ xs: "column", sm: "row"}} spacing={2}>
                        <DesktopDatePicker 
                          label="조회시작일"
                          format="MM/DD/YYYY"
                          value={srchDateFrom}
                          onChange={handleSrchDateFromChange}
                          slotProps={{ textField: { size: "small"}}}
                        />
                        <DesktopDatePicker
                          label="조회종료일"
                          format="MM/DD/YYYY"
                          value={srchDateTo}
                          onChange={handleSrchDateToChange}
                          slotProps={{ textField: { size: "small"}}}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={4}>
                      <Stack spacing={2} direction="row" justifyContent="flex-end">
                        <Button variant="outlined" onClick={() => { downloadExcel() }}>
                          엑셀
                        </Button>
                        <Button variant="contained" onClick={() => { get_statistic_data() }}>
                          검색
                        </Button>
                      </Stack>
                    </Grid>
                  </Stack>
                </Paper>
              </LocalizationProvider>
            </Grid>
          </Grid>
          <Box className="my-account-content-root" >
            <Stack direction={"column"} spacing={2}>
              <Box sx={{ width: "100%", height: gridHeight }}>
                <DataGrid
                  apiRef={gridApi}
                  rows={statisticsList}
                  columns={columns}
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: pageSize,
                      },
                    },
                    sorting: {
                      sortModel: [
                        {
                          field: "range_num",
                          sort: "asc",
                        },
                      ],
                    },
                  }}
                  slots={{ pagination: CustomPagination }}
                  hideFooterSelectedRowCount
                  getRowId={(row) => row.id}
                  rowSelectionModel={selectedRow}
                  onRowSelectionModelChange={(newRowSelectionModel) => {
                    setSelectedRow(newRowSelectionModel);
                  }}
                  paginationModel={selectedPage}
                  onPaginationModelChange={(newPagenationModel) => {
                    setSelectedPage(newPagenationModel);
                  }}
                  onCellDoubleClick={evtGridDblClick}
                />
              </Box>
              <Typography>※ 각 항목을 더블클릭하면 통계 상세페이지로 이동합니다.</Typography>
            </Stack>
          </Box>
        </Stack>
      </Box>
      <Toast ref={toastRef} />
      <LoadingCircle loading={isLoading} />
    </>
  )
}