import { useNavigate, useParams } from "react-router-dom";
import { userState } from "../../interface/MainInterface";
import { Box, Button, Divider, Pagination, Stack, Typography } from "@mui/material";
import { useCallback, useEffect, useRef, useState } from "react";
import { DataGrid, GridCellParams, GridColDef, gridPageCountSelector, gridPageSelector, GridPaginationModel, GridRenderCellParams, GridRowSelectionModel, useGridApiRef, useGridSelector } from "@mui/x-data-grid";
import { CommonUtils } from "../../utils/common_utils";
import LineStatisticChart from "../../utils/chart/LineStatisticChart";
import dayjs from "dayjs";
import { HttpStatisticApi } from "../../interface/statistic-api";
import Toast from "../../utils/Toast";
import LoadingCircle from "../../utils/LoadingCircle";

interface propsType {
  userState: userState;
}

interface statisticsType {
  std_date: string;
  statistics_data: { total_views: string, total_likes: string, watch_time: string}
}

const cUtils = new CommonUtils();
const statisticApi = new HttpStatisticApi();

const pageSize: number = 15;

export default function StatisticsDetail({ userState } : propsType) {
  const toastRef: any = useRef();
  const [isLoading, setIsLoading] = useState(false);

  const { selectedDate } = useParams();
  const gridApi = useGridApiRef();
  const navigate = useNavigate();

  const [selectedRow, setSelectedRow] = useState<GridRowSelectionModel>([]);
  const [selectedPage, setSelectedPage] = useState<GridPaginationModel>({ pageSize: 15, page: 0 });

  const [dataRows, setDataRows] = useState<any>([]);
  const [statisticsList, setStatisticsList] = useState<statisticsType[]>([]);
  const [viewDataRows, setViewDataRows] = useState<object[]>([]);
  const [likeDataRows, setLikeDataRows] = useState<object[]>([]);
  const [watchDataRows, setWatchDataRows] = useState<object[]>([]);

  const fromDate = dayjs(selectedDate).add(-10, "day")
  const toDate = dayjs(selectedDate).add(3, "day")

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "NO",
      width: 30,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "mng_title",
      headerName: "관리 타이틀",
      width: 320,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "sk",
      headerName: "영상ID KEY",
      width: 320,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "total_likes",
      headerName: "좋아요수",
      width: 100,
      headerAlign: "center",
      type: "number",
      align: "center"
    },
    {
      field: "total_views",
      headerName: "시청자수",
      width: 100,
      headerAlign: "center",
      type: "number",
      align: "center"
    },
    {
      field: "watch_time",
      headerName: "시청시간",
      width: 100,
      headerAlign: "center",
      type: "number",
      align: "center",
      renderCell: (param: GridRenderCellParams) => {
        return (
          <>
            <span>{convertWatchTime(param.row.watch_time)}</span>
          </>
        );
      },
    },
  ]

  useEffect(() => {
    if(userState.isPermission !== undefined && userState.isPermission)
      get_date_statistics();

    return () => {
      setDataRows([]);
      setStatisticsList([]);
      setViewDataRows([]);
      setLikeDataRows([]);
      setWatchDataRows([]);
    }
  }, []);

  useEffect(() => {
    if(statisticsList.length > 0) {
      let newDataRows = [...statisticsList];

      const viewCntList = [];
      const likeCntList = [];
      const watchDataList = [];

      for(const data of newDataRows) {
        viewCntList.push({ label: data.std_date, value: data.statistics_data.total_views})
        likeCntList.push({ label: data.std_date, value: data.statistics_data.total_likes})
        watchDataList.push({ label: data.std_date, value: data.statistics_data.watch_time})
      }

      setViewDataRows(viewCntList);
      setLikeDataRows(likeCntList);
      setWatchDataRows(watchDataList);
    }
  }, [statisticsList])

  const get_date_statistics = async () => {
    setIsLoading(true);

    const param = {
      command: "get_date_statistics",
      search_from: dayjs(selectedDate).format("YYYYMMDD"),
      search_to: dayjs(selectedDate).format("YYYYMMDD"),
      sortForward: true,
      user_id: userState.id
    };
    const res = await statisticApi.post(param);
    console.log(res);
    if(res.code === "200") {
      setDataRows(res.response.statistic_data.map((row: object, index: number) => ({...row, id: index + 1})));
    } else {
      toastRef.current?.toast("" + res.response.error_msg, "error", 3000, { vertical: "top", horizontal: "center" });
    }

    setIsLoading(false);
  }

  const fncCellClick = async (video_key: string) => {

    const param = {
      command: "get_detail_statistics",
      search_from: dayjs(fromDate).format("YYYYMMDD"),
      search_to: dayjs(toDate).format("YYYYMMDD"),
      sortForward: true,
      user_id: userState.id,
      pk: video_key
    };

    const res = await statisticApi.post(param);
    if(res.code === "200") {
      setStatisticsList(res.response.statistics_list)
    } else {
      toastRef.current?.toast("" + res.response.error_msg, "error", 3000, { vertical: "top", horizontal: "center" });
    }

  }

  const evtGridDblClick = useCallback(
    (param: GridCellParams) => {
      const url = `/detail/${param.row.sk}`;
      navigate(url);
    },
    [navigate]
  );

  const convertWatchTime = (value: number) => {
    const convertTime = cUtils.convertSecToTime(value)
    
    let result = ""
    if(convertTime.hours === "00")
      result = convertTime.minutes + ":" + convertTime.seconds
    else
      result = convertTime.hours + ":" + convertTime.minutes + ":" + convertTime.seconds
    return result
  }

  function CustomPagination() {
    const page = useGridSelector(gridApi, gridPageSelector);
    const pageCount = useGridSelector(gridApi, gridPageCountSelector);
    return (
      <Box className="tenants-list-pagenation">
        <Pagination
          color="primary"
          shape="rounded"
          count={pageCount === 0 ? 1 : pageCount}
          page={page + 1}
          siblingCount={15}
          onChange={(e, v) => gridApi.current.setPage(v - 1)}
        ></Pagination>
      </Box>
    );
  }

  const downloadExcel = () => {
    let header: any = [
      [
        "NO",
        "영상ID KEY",
        "좋아요 수",
        "시청자 수",
        "시청 시간",
      ],
    ];
    let index = 1;
    for (const data of dataRows) {
      const detail = [
        index,
        data.sk,
        data.total_likes,
        data.total_views,
        convertWatchTime(data.watch_time)
      ];
      header = [...header, detail];
      index += 1;
    }
    const colWidth = [
      { wpx: 40 },
      { wpx: 240 },
      { wpx: 200 },
      { wpx: 200 },
      { wpx: 200 },
    ];
    const sheetName = "통계내역";

    const fileName = dayjs(selectedDate).format("YYYY-MM-DD") + "_세부 통계_.xlsx";
    cUtils.downloadExcel(header, colWidth, sheetName, fileName);
  };

  return (
    <>
      <Box sx={{ p: 0, width: "100%", height: "100%"}}>
        <Box sx={{ p: 0, height: "100%"}}>
          <Stack direction="column" spacing={0}>
            <Box className="statistics-content-header">
              <Typography variant="h5" gutterBottom className="my-account-header-title">
                세부 통계 ({dayjs(selectedDate).format("YYYY-MM-DD")})
              </Typography>
              <Box sx={{ pt: "10px", minWidth: "200px", display: "flex", flexDirection: "row", gap: "16px", justifyContent: "flex-end"}}>
                <Button variant="contained" color="primary" onClick={downloadExcel}>
                  엑셀
                </Button>
              </Box>
            </Box>
            <Divider />
            <Box className="my-account-content-root" sx={{ pt: 2}}>
              <Stack direction="row" spacing={2}>
                <Box sx={{ width: "50%", height: "900px"}}>
                  <DataGrid
                    apiRef={gridApi}
                    rows={dataRows}
                    columns={columns}
                    initialState={{
                      pagination: {
                        paginationModel: {
                          pageSize: pageSize,
                        },
                      },
                      sorting: {
                        sortModel: [
                          {
                            field: "range_num",
                            sort: "asc",
                          },
                        ],
                      },
                    }}
                    slots={{ pagination: CustomPagination }}
                    hideFooterSelectedRowCount
                    getRowId={(row) => row.id}
                    rowSelectionModel={selectedRow}
                    onRowSelectionModelChange={(newRowSelectionModel) => {
                      setSelectedRow(newRowSelectionModel);
                    }}
                    paginationModel={selectedPage}
                    onPaginationModelChange={(newPagenationModel) => {
                      setSelectedPage(newPagenationModel);
                    }}
                    onCellClick={(param) => {fncCellClick(param.row.sk)}}
                    onCellDoubleClick={evtGridDblClick}
                  />
                </Box>
                <Box sx={{ width: "50%"}}>
                <Stack direction="column" spacing={2}>
                    <Box sx={{ width: "100%", height: "300px"}}>
                      <LineStatisticChart
                        dataRows={[viewDataRows]}
                        chartTitle={{ display: true, text: "통계데이터"}}
                        labelTitles={["시청자수"]}
                        xName="날짜"
                        yName="계"
                        lineColors={["#dc6884"]}
                        backgroundColors={["#dc688480"]}
                        legend={{ display: true, position: "bottom"}}
                        yValueConvert={false}
                      />
                    </Box>
                  </Stack>
                  <Stack direction="column" spacing={2}>
                    <Box sx={{ width: "100%", height: "300px"}}>
                      <LineStatisticChart
                        dataRows={[likeDataRows]}
                        chartTitle={{ display: true, text: "통계데이터"}}
                        labelTitles={["좋아요수"]}
                        xName="날짜"
                        yName="계"
                        lineColors={["#98bde7"]}
                        backgroundColors={["#98bde780"]}
                        legend={{ display: true, position: "bottom"}}
                        yValueConvert={false}
                      />
                    </Box>
                  </Stack>
                  <Stack direction="column" spacing={2}>
                    <Box sx={{ width: "100%", height: "300px"}}>
                      <LineStatisticChart
                        dataRows={[watchDataRows]}
                        chartTitle={{ display: true, text: "통계데이터"}}
                        labelTitles={["시청시간"]}
                        xName="날짜"
                        yName="계"
                        lineColors={["#A5B68D"]}
                        backgroundColors={["#C1CFA1"]}
                        legend={{ display: true, position: "bottom"}}
                        yValueConvert={true}
                      />
                    </Box>
                  </Stack>
                </Box>
              </Stack>
            </Box>
          </Stack>
        </Box>
      </Box>
      <Toast ref={toastRef} />
      <LoadingCircle loading={isLoading} />
    </>
  )
}