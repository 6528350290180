import { useEffect, useRef, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";

import {
  Box,
  Stack,
  Typography,
  Paper,
  Card,
  CardActionArea,
  CardContent,
  Avatar,
  SpeedDial,
  SpeedDialAction,
  CardMedia,
} from "@mui/material";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import ShareIcon from "@mui/icons-material/Share";

import { HttpMainApi } from "../../interface/main-api";

import { userState } from "../../interface/MainInterface";
import LoadingCircle from "../../utils/LoadingCircle";
import Toast from "../../utils/Toast";

declare global {
  interface Window {
    IVSPlayer: any;
  }
}

interface propsType {
  userState: userState;
  shortInfo: any;
  callback: any;
}

const mainApi = new HttpMainApi();

const CardShort = (props: propsType) => {
  const navigate = useNavigate();
  const toastRef: any = useRef();
  const posterRef: any = useRef();

  const [isLoading, setIsLoading] = useState(false);
  const [shortInfo, setShortInfo] = useState<any>({});
  const [posterUrl, setPosterUrl] = useState("");

  const [open, setOpen] = useState(false);

  useEffect(() => {
    get_short_play_list_info();
    return () => {
      setIsLoading(false);
      setShortInfo({});
      setPosterUrl("");
      setOpen(false);
    };
  }, []);

  const get_first_video_info = async (video_key: string) => {
    if (video_key !== "") {
      const param: any = {
        command: "get_video_info",
        video_key: video_key,
      };

      const res = await mainApi.post(param);
      console.log("res: ", res);
      if (res.code === "200") {
        // 썸네일 처리
        const video_default_thumb = res.response.video_info.video_thumb === undefined ? "video" : res.response.video_info.video_thumb;
        const tumbnal = res.response.video_info.thumbnail_url === undefined ? "" : res.response.video_info.thumbnail_url;
        const custom_tumbnal = res.response.video_info.custom_tumbnail === undefined ? "" : res.response.video_info.custom_tumbnail;

        if (tumbnal !== "" || custom_tumbnal !== "") {
          if (video_default_thumb === "video") {
            setPosterUrl(tumbnal);
          } else {
            setPosterUrl(custom_tumbnal);
          }
        } else {
          setPosterUrl("/images/no-image.jpg");
        }
      }
    } else {
      setPosterUrl("/images/no-image.jpg");
    }
  };

  // 비디오 정보 조회
  const get_short_play_list_info = async () => {
    const param: any = {
      command: "get_short_play_list_info",
      short_info: {
        key: props.shortInfo.sk,
      },
      user_info: {
        id: props.userState.id,
      },
    };

    const res = await mainApi.post(param);
    if (res.code === "200") {
      setShortInfo(res.response.short_info);
      get_first_video_info(res.response.short_info.play_list?.length > 0 ? res.response.short_info.play_list[0].video_key : "");
    } else {
      console.error(">>>", res.response.error_msg);
    }
  };

  // 상세정보 페이지 이동
  const goDetail = useCallback(() => {
    navigate(`/shortDetail/${props.shortInfo.sk}`);
  }, []);

  return (
    <>
      <Box sx={{ p: 0, height: "100%" }}>
        <Card sx={{ position: "relative" }}>
          <CardActionArea sx={{ position: "relative" }} onClick={goDetail}>
            <CardMedia component="img" image={posterUrl} />
          </CardActionArea>
          <CardContent sx={{ p: 0, pl: 1, pr: 1, pb: "8px !important" }}>
            <Stack direction={"column"} spacing={1}>
              <Box>
                <Typography
                  sx={{
                    fontWeight: "800 !important",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    lineHeight: "40px",
                  }}
                >
                  {shortInfo.title}
                </Typography>
              </Box>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Stack direction={"row"} spacing={1}>
                  <Avatar sx={{ bgcolor: "#9d97c7", width: "36px", height: "36px" }}>{shortInfo.pk?.split("#")[1][0]}</Avatar>
                  <Typography
                    sx={{
                      marginTop: "7px !important",
                      fontSize: "14px !important",
                      color: "#7a7a7a !important",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {shortInfo.pk?.split("#")[1]}
                  </Typography>
                </Stack>
                <Typography sx={{ fontSize: "11px !important", color: "#00000080 !important", lineHeight: "39px" }}>{`${dayjs(
                  shortInfo.create_time
                ).format("YYYY-MM-DD")}`}</Typography>
              </Box>
            </Stack>
          </CardContent>
        </Card>
      </Box>
      <LoadingCircle loading={isLoading} />
      <Toast ref={toastRef} />
    </>
  );
};

export default CardShort;
