import { useEffect, useRef, useState } from "react";
import { userState } from "../../interface/MainInterface";
import { Box, Button, Grid, Paper, Stack } from "@mui/material";
import LineStatisticChart from "../../utils/chart/LineStatisticChart";
import dayjs, { Dayjs } from "dayjs";
import { HttpStatisticApi } from "../../interface/statistic-api";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import LoadingCircle from "../../utils/LoadingCircle";
import Toast from "../../utils/Toast";

interface propsType {
  userState: userState
}

const statisticApi = new HttpStatisticApi();

const DashboardInfo = (props: propsType) => {
  const toastRef: any = useRef();
  const [isLoading, setIsLoading] = useState(false);

  const [statisticsList, setStatisticsList] = useState<any>([]);
  const [viewDataRows, setViewDataRows] = useState<any>([]);
  const [likeDataRows, setLikeDataRows] = useState<any>([]);
  const [watchDataRows, setWatchDataRows] = useState<any>([]);
  const [srchDateFrom, setSrchDateFrom] = useState<Dayjs | null>(dayjs().add(-1, "month"));
  const [srchDateTo, setSrchDateTo] = useState<Dayjs | null>(dayjs()); // 오늘날짜

  const handleSrchDateFromChange = (newValue: Dayjs | null) => {
    setSrchDateFrom(newValue);
  };
  const handleSrchDateToChange = (newValue: Dayjs | null) => {
    setSrchDateTo(newValue);
  };

  useEffect(() => {
    if(props.userState.isPermission !== undefined && props.userState.isPermission)
      get_statistic_data();

    return () => {
      setStatisticsList([]);
      setViewDataRows([]);
      setLikeDataRows([]);
      setWatchDataRows([]);
    }
  }, []);

  useEffect(() => {
    if(statisticsList.length > 0) {
      let newDataRows = [...statisticsList];

      const viewCntList = [];
      const likeCntList = [];
      const watchDataList = [];

      for(const data of newDataRows) {
        viewCntList.push({ label: data.statistic_date, value: data.statistic_data.total_views});
        likeCntList.push({ label: data.statistic_date, value: data.statistic_data.total_likes});
        watchDataList.push({ label: data.statistic_date, value: data.statistic_data.watch_time});
      }

      setViewDataRows(viewCntList);
      setLikeDataRows(likeCntList);
      setWatchDataRows(watchDataList);
    }

  }, [statisticsList]);

  const get_statistic_data = async () => {
    setIsLoading(true);

    const param = {
      command: "get_statistics_data",
      search_from: dayjs(srchDateFrom).format("YYYYMMDD"),
      search_to: dayjs(srchDateTo).format("YYYYMMDD"),
      sortForward: true,
      user_id: props.userState.id
    };

    const res = await statisticApi.post(param);
    if(res.code === "200") {
      setStatisticsList(res.response.statistic_data);
    } else {
      toastRef.current?.toast("" + res.response.error_msg, "error", 3000, { vertical: "top", horizontal: "center" });
    }
    setIsLoading(false);
  }

  return (
    <>
      <Box sx={{ p: 0, width: "100%", height: "100%"}}>
        <Grid container spacing={1.5} sx={{ px: 1, py: 0.5, width: "100%"}}>
          <Grid item xs={12}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Paper sx={{ p: 2}} elevation={5}>
                <Stack spacing={2} direction="row" display="flex" alignItems="center">
                  <Grid item xs={8}>
                    <Stack direction={{ xs: "column", sm: "row"}} spacing={2}>
                      <DesktopDatePicker 
                        label="조회시작일"
                        format="MM/DD/YYYY"
                        value={srchDateFrom}
                        onChange={handleSrchDateFromChange}
                        slotProps={{ textField: { size: "small"}}}
                      />
                      <DesktopDatePicker
                        label="조회종료일"
                        format="MM/DD/YYYY"
                        value={srchDateTo}
                        onChange={handleSrchDateToChange}
                        slotProps={{ textField: { size: "small"}}}
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={4}>
                    <Stack spacing={2} direction="row" justifyContent="flex-end">
                      <Button variant="contained" onClick={() => { get_statistic_data() }}>
                        검색
                      </Button>
                    </Stack>
                  </Grid>
                </Stack>
              </Paper>
            </LocalizationProvider>
          </Grid>
        </Grid>
        <Stack direction="row" spacing={2}>
          <Box sx={{ width: "100%", height: "400px"}}>
          <LineStatisticChart
            dataRows={[viewDataRows]}
            chartTitle={{ display: true, text: "통계데이터"}}
            labelTitles={["시청자수"]}
            xName="날짜"
            yName="계"
            lineColors={["#dc6884"]}
            backgroundColors={["#dc688480"]}
            legend={{ display: true, position: "bottom"}}
            yValueConvert={false}
          />
          </Box>
        </Stack>
        <Stack direction="row" spacing={2}>
          <Box sx={{ width: "100%", height: "400px"}}>
            <LineStatisticChart
              dataRows={[likeDataRows]}
              chartTitle={{ display: true, text: "통계데이터"}}
              labelTitles={["좋아요수"]}
              xName="날짜"
              yName="계"
              lineColors={["#98bde7"]}
              backgroundColors={["#98bde780"]}
              legend={{ display: true, position: "bottom"}}
              yValueConvert={false}
            />
          </Box>
        </Stack>
        <Stack direction="row" spacing={2}>
          <Box sx={{ width: "100%", height: "400px"}}>
            <LineStatisticChart
              dataRows={[watchDataRows]}
              chartTitle={{ display: true, text: "통계데이터"}}
              labelTitles={["시청시간"]}
              xName="날짜"
              yName="계"
              lineColors={["#A5B68D"]}
              backgroundColors={["#C1CFA1"]}
              legend={{ display: true, position: "bottom"}}
              yValueConvert={true}
            />
          </Box>
        </Stack>
      </Box>
      <Toast ref={toastRef} />
      <LoadingCircle loading={isLoading} />
    </>
  )
}

export default DashboardInfo;
