import { useEffect, useRef, useState } from "react";
import Chart from "chart.js/auto";
import { CategoryScale, TooltipItem } from "chart.js";
import { Line } from "react-chartjs-2";
import { CommonUtils } from "../common_utils";

interface propsType {
  dataRows: any;
  chartTitle: any;
  labelTitles: any;
  xName: string;
  yName: string;
  lineColors: any;
  backgroundColors: any;
  legend: any;
  yValueConvert: boolean;
}

interface IGridCols {
  label: string;
  value: number;
}

Chart.register(CategoryScale);

const cUtils = new CommonUtils();

const LineStatisticChart = (props: propsType) => {
  const convertTime = (input: string) => {
    return input.substring(4, 6) + "." + input.substring(6, 8);
  };

  const chartRef : any = useRef();
  const [chartData, setChartData] = useState<any>({});
  const [stepSize, setStepSize] = useState(10);

  useEffect(() => {
    if (props.dataRows.length > 0) {
      let newDataRows = [...props.dataRows];
      const labels: any = newDataRows[0].map((data: IGridCols) => convertTime(data.label));
      const datasets: any = [];
      // eslint-disable-next-line array-callback-return
      let maxValue = 0;
      // eslint-disable-next-line array-callback-return
      newDataRows.map((data: any, index: number) => {
        const temp: any = {
          label: props.labelTitles[index],
          data: data.map((col: IGridCols) => {
            if (col.value > maxValue) maxValue = col.value;
            return col.value;
          }),
          borderColor: props.lineColors[index],
          backgroundColor: props.backgroundColors[index],
        };
        datasets.push(temp);
      });
      const length = maxValue.toString().length;
      setStepSize(length > 5 ? 10000 : length > 4 ? 1000 : length > 3 ? 100 : length > 2 ? 10 : 1);
      let data: any = {
        labels: labels,
        datasets: datasets,
      };

      // console.log(data);
      setChartData(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.dataRows]);

  useEffect(() => {
    console.log(chartRef.current);
    return () => {
      // 메모리 누수를 방지하기 위해서 컴포넌트 언마운트시 State를 초기화 한다.
      setChartData({});
    };
  }, []);

  const handleDoubleClick = (event: React.MouseEvent<HTMLCanvasElement>) => {
    const chart = chartRef.current;
    console.log("click")
    if(chart) {
      const dataset = chart.getElementsAtEventForMode(
        event.nativeEvent,
        "nearest",
        { intersect: true},
        true
      );
      if(dataset.length) {
        const datasetIndex = dataset[0].datasetIndex;
        console.log("double clicked on dataset index: ", datasetIndex);
      }
    }
  }

  return (
    <div className="chart-root">
      {!cUtils.isEmptyObj(chartData) && (
        <Line
          ref={chartRef}
          onDoubleClickCapture={handleDoubleClick}
          data={chartData}
          options={{
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              title: {
                display: props.chartTitle.display,
                text: props.chartTitle.text,
              },
              legend: {
                display: props.legend.display,
                position: props.legend.position,
              },
              tooltip: {
                callbacks: {
                  label: function(context: TooltipItem<'line'>) {
                    const value = context.raw as number;
                    
                    if(props.yValueConvert) {
                      const convertData = cUtils.convertSecToTime(value)
                      return props.labelTitles[0] + ": " + convertData.hours + ":" + convertData.minutes + ":" + convertData.seconds
                    }
                  }
                }
              }
            },
            interaction: {
              intersect: false,
            },
            scales: {
              x: {
                display: true,
                title: {
                  display: false,
                  text: props.xName,
                },
              },
              y: {
                display: true,
                title: {
                  display: true,
                  text: props.yName,
                },
                beginAtZero: true,
                ticks: {
                  callback: function(value: number | string) {
                    if(props.yValueConvert) {
                      const convertData = cUtils.convertSecToTime(value as number)
                      return convertData.hours + ":" + convertData.minutes + ":" + convertData.minutes
                    }
                    return value
                  },
                  stepSize: stepSize,
                },
              },
            },
          }}
        />
      )}
    </div>
  );
};

export default LineStatisticChart;
