import { useEffect, useRef, useState, forwardRef, useCallback, ChangeEvent } from "react";
import { v4 as uuidv4 } from "uuid";

import { Box, CardMedia, Stack, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Input from "@mui/material/Input";
import UploadIcon from "@mui/icons-material/Upload";

import { HttpUnAuthApi } from "../../interface/unauth-api";
import { userState } from "../../interface/MainInterface";

import Toast from "../Toast";

interface propsType {
  userState: userState;
  uploadDir: string;
  callBack: any;
  multiple: boolean;
  setIsLoading: any;
  borderRadius: string;
  width: string;
  variant: any;
  buttonText: string;
  dragDrop: boolean;
  acceptableFormats: any;
}

const unAuthApi = new HttpUnAuthApi();

const S3UploaderViaApi = (props: propsType, ref: any) => {
  const toastRef: any = useRef();
  const attachInputRef: any = useRef();

  const [click, setClick] = useState(false);
  const [targetCnt, setTargetCnt] = useState(0);
  const [okCnt, setOkCnt] = useState(0);
  const [resultFiles, setResultFiles] = useState<any>([]);
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const dragRef = useRef<HTMLLabelElement | null>(null);

  const fncUpload = () => {
    setTargetCnt(0);
    setOkCnt(0);
    setResultFiles([]);
    attachInputRef.current?.click();
  };

  const handleAttachInput = useCallback(
    async (e: ChangeEvent<HTMLInputElement> | any) => {
      setTargetCnt(0);
      setOkCnt(0);
      setResultFiles([]);

      let selectFiles: File[] = [];
      if (e.type === "drop") {
        // 드래그 앤 드롭 했을때
        selectFiles = e.dataTransfer.files;
      } else {
        // "파일 첨부" 버튼을 눌러서 이미지를 선택했을때
        selectFiles = e.target.files;
      }
      if (selectFiles.length > 0) {
        const files: any = selectFiles;
        // for (let i = 0; i < files.length; i++) {
        //   const file = files[i];
        //   console.log("file.type : ", file.type);
        //   if (!props.acceptableFormats.includes(file.type)) {
        //     alert("지원하지 않는 파일입니다.");
        //     return;
        //   }
        // }
        setTargetCnt(files.length);
        let result: any = [...resultFiles];
        props.setIsLoading(true);
        let cnt = 0;
        for await (const fileInfo of files) {
          const file = fileInfo;
          const type = file.name.split(".").pop();
          const filePath = `${props.uploadDir}${uuidv4()}.${type}`;
          // 파일 업로드
          const param: any = {
            command: "get_cf_presigned_url",
            key: filePath,
          };

          const res = await unAuthApi.post(param);
          if (res.code === "200") {
            const upload_url = res.response.upload_url;
            const uploadRes = await fetch(upload_url, {
              method: "PUT",
              headers: { "Content-Type": `${file.type}`, acl: "public-read" },
              body: fileInfo,
            });
            if (uploadRes.status === 200) {
              result = [...result, { FILE_NAME: file.name, FILE_PATH: res.response.object_url }];
              setOkCnt((setOkCnt) => setOkCnt + 1);
            } else {
              props.callBack({ status: "fail" });
              break;
            }
          }

          cnt++;
          if (cnt === files.length) {
            setResultFiles(result);
            props.setIsLoading(false);
          }
        }
      }
    },
    [resultFiles]
  );

  useEffect(() => {
    if (okCnt === targetCnt && targetCnt > 0) {
      props.callBack({ status: "ok", fileInfo: resultFiles });
      setClick(false);
    } else setClick(true);
  }, [okCnt, targetCnt]);

  useEffect(() => {
    setClick(false);
    setTargetCnt(0);
    setOkCnt(0);
    setResultFiles([]);
  }, []);

  const handleDragIn = useCallback((e: DragEvent): void => {
    e.preventDefault();
    e.stopPropagation();
  }, []);

  const handleDragOut = useCallback((e: DragEvent): void => {
    e.preventDefault();
    e.stopPropagation();

    setIsDragging(false);
  }, []);

  const handleDragOver = useCallback((e: DragEvent): void => {
    e.preventDefault();
    e.stopPropagation();

    if (e.dataTransfer!.files) {
      setIsDragging(true);
    }
  }, []);

  const handleDrop = useCallback(
    (e: DragEvent): void => {
      e.preventDefault();
      e.stopPropagation();

      handleAttachInput(e);
      setIsDragging(false);
    },
    [handleAttachInput]
  );

  const initDragEvents = useCallback((): void => {
    // 앞서 말했던 4개의 이벤트에 Listener를 등록합니다. (마운트 될때)

    if (dragRef.current !== null) {
      dragRef.current.addEventListener("dragenter", handleDragIn);
      dragRef.current.addEventListener("dragleave", handleDragOut);
      dragRef.current.addEventListener("dragover", handleDragOver);
      dragRef.current.addEventListener("drop", handleDrop);
    }
  }, [handleDragIn, handleDragOut, handleDragOver, handleDrop]);

  const resetDragEvents = useCallback((): void => {
    // 앞서 말했던 4개의 이벤트에 Listener를 삭제합니다. (언마운트 될때)

    if (dragRef.current !== null) {
      dragRef.current.removeEventListener("dragenter", handleDragIn);
      dragRef.current.removeEventListener("dragleave", handleDragOut);
      dragRef.current.removeEventListener("dragover", handleDragOver);
      dragRef.current.removeEventListener("drop", handleDrop);
    }
  }, [handleDragIn, handleDragOut, handleDragOver, handleDrop]);

  useEffect(() => {
    initDragEvents();

    return () => resetDragEvents();
  }, [initDragEvents, resetDragEvents]);

  return (
    <>
      <Input
        id="fileUpload"
        key="attachment"
        color="primary"
        type="file"
        inputProps={{ multiple: props.multiple, accept: props.acceptableFormats }}
        inputRef={attachInputRef}
        onChange={handleAttachInput}
        sx={{ display: "none" }}
      ></Input>
      {props.dragDrop && (
        <label className={isDragging ? "DragDrop" : "DragDrop"} htmlFor="fileUpload" ref={dragRef}>
          <Box sx={{ position: "relative", width: "100%", height: "100%" }}>
            <Stack
              sx={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                border: "3px dashed rgba(0, 0, 0, 0.5);",
                boxSizing: "border-box",
                borderRadius: "7px",
                ...(isDragging ? { backgroundColor: "#8cb9d9" } : {}),
              }}
            >
              <CardMedia component="img" image={"/images/upload.png"} sx={{ width: "30%" }} />
              <Typography>Drag & Drop video</Typography>
            </Stack>
          </Box>
        </label>
      )}
      <Button
        variant={props.variant ? props.variant : "contained"}
        sx={{ width: props.width, ...(props.borderRadius !== "" && { borderRadius: props.borderRadius }) }}
        onClick={() => {
          fncUpload();
        }}
        fullWidth
        disabled={click}
        color="warning"
        startIcon={<UploadIcon />}
      >
        {props.buttonText}
      </Button>
      <Toast ref={toastRef} />
    </>
  );
};

export default forwardRef(S3UploaderViaApi);
