import { useCallback, useEffect, useRef, useState, forwardRef, useImperativeHandle } from "react";
import {
  Drawer,
  Box,
  Grid,
  Stack,
  Modal,
  Typography,
  Button,
  TextField,
  Tooltip,
  IconButton,
  Divider,
  FormControlLabel,
  Switch,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";

import Toast from "../../utils/Toast";
import LoadingCircle from "../../utils/LoadingCircle";
import { userState } from "../../interface/MainInterface";

import { CommonUtils } from "../../utils/common_utils";
import { HttpMainApi } from "../../interface/main-api";

import "./DrawerVideoProd.css";

interface propsType {
  userState: userState;
  videoInfo: any;
  callback: any;
}

const mainApi = new HttpMainApi();

const DrawerVideoProd = (props: propsType, ref: any) => {
  const cUtils = new CommonUtils();

  const toastRef: any = useRef();
  const productIdRef: any = useRef();
  const productNameRef: any = useRef();
  const salePriceRef: any = useRef();
  const thumbnailUrlRef: any = useRef();
  const linkUrlRef: any = useRef();
  const eventCallbackRef: any = useRef();

  const [isLoading, setIsLoading] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);

  const [prodList, setProdList] = useState([] as any);
  const [prodRender, setProdRender] = useState([] as any);

  const [productId, setProductId] = useState("");
  const [productName, setProductName] = useState("");
  const [salePrice, setSalePrice] = useState("");
  const [thumbnailUrl, setThumbnailUrl] = useState("");
  const [linkUrl, setLinkUrl] = useState("");
  const [eventCallback, setEventCallback] = useState("");
  const [display, setDisplay] = useState(true);

  useEffect(() => {
    return () => {
      setIsLoading(false);
      setOpenDrawer(false);
      setProdList([]);
      setProdRender([]);
      setProductId("");
      setProductName("");
      setSalePrice("");
      setThumbnailUrl("");
      setEventCallback("");
      setDisplay(true);
    };
  }, []);

  useEffect(() => {
    if (props.videoInfo.prod_list !== undefined) setProdList(props.videoInfo.prod_list);
    else setProdList([]);
  }, [props.videoInfo.prod_list]);

  useEffect(() => {
    setProdRender(
      prodList.map((obj: any, index: number) => {
        return (
          <div key={`prod${index}`}>
            <Box className="thd-prod-item-box">
              <Box className="thd-prod-item-info" sx={{ filter: obj.soldout ? "opacity(0.5)" : "none" }}>
                <Box className="thd-prod-image-box">
                  <img src={obj.thumbImageUrl} alt="" />
                </Box>
                <Box className="thd-prod-desc-box">
                  <div className="thd-prod-desc-title">{obj.name}</div>
                  <div className="thd-prod-desc-price">{obj.soldout ? "품절" : cUtils.numericComma(obj.salePrice) + "원"}</div>
                </Box>
                <Box className="thd-prod-image-box">
                  <Tooltip title={"삭제"}>
                    <Button
                      variant="contained"
                      onClick={() => {
                        // delete video
                        delete_video_prod(index);
                      }}
                      color="error"
                      sx={{ height: "60px" }}
                    >
                      <DeleteIcon />
                    </Button>
                  </Tooltip>
                </Box>
              </Box>
              <Divider light />
            </Box>
          </div>
        );
      })
    );
  }, [prodList]);

  const open = useCallback(() => {
    setOpenDrawer(true);
  }, []);

  const addProduct = async () => {
    if (productId === undefined || productId === "") {
      toastRef.current?.toast("상품ID를 입력하세요.", "error", 4000, { vertical: "top", horizontal: "center" });
      productIdRef.current.focus();
      return;
    }

    if (productName === undefined || productName === "") {
      toastRef.current?.toast("상품명을 입력하세요.", "error", 4000, { vertical: "top", horizontal: "center" });
      productNameRef.current.focus();
      return;
    }

    if (salePrice === undefined || salePrice === "") {
      toastRef.current?.toast("상품금액을 입력하세요.", "error", 4000, { vertical: "top", horizontal: "center" });
      salePriceRef.current.focus();
      return;
    }

    if (thumbnailUrl === undefined || thumbnailUrl === "") {
      toastRef.current?.toast("썸네일URL을 입력하세요.", "error", 4000, { vertical: "top", horizontal: "center" });
      thumbnailUrlRef.current.focus();
      return;
    }

    if (eventCallback === undefined || eventCallback === "") {
      toastRef.current?.toast("콜백JSON을 입력하세요.", "error", 4000, { vertical: "top", horizontal: "center" });
      eventCallbackRef.current.focus();
      return;
    }

    const newProduct: any = {
      productId: productId,
      thumbImageUrl: thumbnailUrl,
      name: productName,
      salePrice: parseInt(salePrice),
      display: display,
      callback: eventCallback,
    };
    const newProductList: any = [...prodList, newProduct];

    const param: any = {
      command: "update_video_product",
      video_info: {
        key: props.videoInfo.sk,
      },
      prod_list: newProductList,
      user_info: {
        id: props.userState.id,
      },
    };

    setIsLoading(true);
    const res = await mainApi.post(param);
    if (res.code === "200") {
      props.callback({ command: "add_prod_finish" });
    } else {
      console.error(res.response.error_msg);
      toastRef.current?.toast("" + res.response.error_msg, "error", 4000, { vertical: "top", horizontal: "center" });
    }
    setIsLoading(false);
  };

  const delete_video_prod = async (deleteIndex: number) => {
    if (!window.confirm("해당 상품을 삭제합니다.\n삭제한 데이터는 복구할 수 없습니다.")) return;
    const newProductList: any = [...prodList];
    newProductList.splice(deleteIndex, 1);

    const param: any = {
      command: "update_video_product",
      video_info: {
        key: props.videoInfo.sk,
      },
      prod_list: newProductList,
      user_info: {
        id: props.userState.id,
      },
    };

    setIsLoading(true);
    const res = await mainApi.post(param);
    if (res.code === "200") {
      props.callback({ command: "delete_prod_finish" });
    } else {
      console.error(res.response.error_msg);
      toastRef.current?.toast("" + res.response.error_msg, "error", 4000, { vertical: "top", horizontal: "center" });
    }
    setIsLoading(false);
  };

  const close = () => {
    setOpenDrawer(false);
  };

  // 부모 Component에서 접근 가능하도록 함수 전달.
  useImperativeHandle(ref, () => ({
    open,
  }));

  return (
    <>
      <Drawer anchor={"right"} open={openDrawer} onClose={close} PaperProps={{ sx: { minWidth: "50%", height: "100%", p: 3 } }}>
        <Stack direction={"column"} spacing={4}>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
              <Typography className="video-detail-info-title">{`Products (${prodList.length}건)`}</Typography>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Tooltip title="창닫기">
                <IconButton aria-label="close" onClick={close}>
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
          <Box sx={{ width: "100%" }} className={"thd-prod-scroll prod-box"}>
            <nav>
              <div className="thd-prod-list">{prodRender}</div>
            </nav>
          </Box>
          <Box>
            <Stack direction={"column"} spacing={2}>
              <TextField
                id="txtProductId"
                inputRef={productIdRef}
                value={productId}
                label="상품ID를 입력하세요."
                placeholder="40A1822831"
                fullWidth
                onChange={(e) => {
                  setProductId(e.target.value);
                }}
                size="small"
                type="search"
                autoComplete="off"
                inputProps={{ enterKeyHint: "Enter" }}
              />
              <TextField
                id="txtProductId"
                inputRef={productNameRef}
                value={productName}
                label="상품명을 입력하세요."
                placeholder="롤랜드 HP702 헤드폰 높낮이 의자 증정"
                fullWidth
                onChange={(e) => {
                  setProductName(e.target.value);
                }}
                size="small"
                type="search"
                autoComplete="off"
                inputProps={{ enterKeyHint: "Enter" }}
              />
              <TextField
                id="numSalePrice"
                inputRef={salePriceRef}
                value={salePrice}
                label="상품가격을 입력하세요."
                placeholder="5760000"
                fullWidth
                onChange={(e) => {
                  setSalePrice(e.target.value);
                }}
                size="small"
                type="number"
                autoComplete="off"
                inputProps={{ enterKeyHint: "Enter" }}
              />
              <TextField
                id="txtImageUrl"
                inputRef={thumbnailUrlRef}
                value={thumbnailUrl}
                label="썸네일 이미지 경로를 입력하세요."
                placeholder="https://image.thehyundai.com/static/9/7/1/82/A1/40A1821799_0_300.jpg"
                fullWidth
                onChange={(e) => {
                  setThumbnailUrl(e.target.value);
                }}
                size="small"
                type="search"
                autoComplete="off"
                inputProps={{ enterKeyHint: "Enter" }}
              />
              <TextField
                id="txtEventCallback"
                inputRef={eventCallbackRef}
                value={eventCallback}
                label="콜백JSON을 입력하세요.(상품 클릭시 postMessage로 입력하신 payload를 보냅니다.)"
                placeholder='{msg: "showProdDetail", productId: "40A1822831"}'
                fullWidth
                onChange={(e) => {
                  setEventCallback(e.target.value);
                }}
                size="small"
                type="search"
                autoComplete="off"
                inputProps={{ enterKeyHint: "Enter" }}
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={display}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setDisplay(event.target.checked);
                    }}
                  />
                }
                label="전시여부"
              />
              <Tooltip title={"관련 상품 추가"}>
                <Button
                  variant="contained"
                  fullWidth
                  startIcon={<AddIcon />}
                  onClick={() => {
                    addProduct();
                  }}
                >
                  {"관련 상품 추가"}
                </Button>
              </Tooltip>
            </Stack>
          </Box>
        </Stack>
      </Drawer>
      <Toast ref={toastRef} />
      <LoadingCircle loading={isLoading} />
    </>
  );
};

export default forwardRef(DrawerVideoProd);
