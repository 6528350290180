import { useEffect, useState } from "react";
import Chart from "chart.js/auto";
import { CategoryScale } from "chart.js";
import { Line } from "react-chartjs-2";

interface propsType {
  dataRows: any;
  chartTitle: string;
  labelTitle: string;
  xName: string;
  yName: string;
  lineColor: string;
  convertFormat: any;
  displayX: boolean;
  displayY: boolean;
  yStep: number;
}

interface IGridCols {
  label: string;
  value: number;
}

Chart.register(CategoryScale);

const LineChart = (props: propsType) => {
  const [chartData, setChartData] = useState<any>();

  useEffect(() => {
    if (props.dataRows.length > 0) {
      let newDataRows = [...props.dataRows];
      setChartData({
        labels: newDataRows.map((data: IGridCols) => props.convertFormat(data.label)),
        datasets: [
          {
            label: props.labelTitle,
            data: props.dataRows.map((data: IGridCols) => data.value),
            borderColor: props.lineColor,
          },
        ],
      });
    } else {
      setChartData({
        labels: [],
        datasets: [{ label: props.labelTitle, data: [], borderColor: props.lineColor }],
      });
    }
  }, [props.dataRows]);

  useEffect(() => {
    return () => {
      // 메모리 누수를 방지하기 위해서 컴포넌트 언마운트시 State를 초기화 한다.
      setChartData({});
    };
  }, []);

  return (
    <div className="chart-root">
      {chartData && (
        <Line
          data={chartData}
          options={{
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              title: {
                display: true,
                text: props.chartTitle,
              },
              legend: {
                display: false,
                position: "top",
              },
            },
            interaction: {
              intersect: false,
            },
            scales: {
              x: {
                display: props.displayX,
                title: {
                  display: false,
                  text: props.xName,
                },
              },
              y: {
                display: props.displayY,
                title: {
                  display: true,
                  text: props.yName,
                },
                beginAtZero: true,
                ticks: {
                  stepSize: props.yStep,
                },
              },
            },
          }}
        />
      )}
    </div>
  );
};

export default LineChart;
