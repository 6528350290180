import { userState } from "../../interface/MainInterface";
import { Box, Stack } from "@mui/material";
import DashboardInfo from "./DashboardInfo";

interface propsType {
  userState: userState;
}

const MainDashboard = (props: propsType) => {

  return (                    
    <>
      <Box className="content-content-root">
        <Stack direction="column" spacing={2}>
          <DashboardInfo userState={props.userState} />
        </Stack>
      </Box>
    </>
  )
}

export default MainDashboard;