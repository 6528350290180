import { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { Stack, Tooltip, IconButton, CardMedia, TableCell, TableRow } from "@mui/material";
import PlaylistRemoveIcon from "@mui/icons-material/PlaylistRemove";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ShuffleOnIcon from "@mui/icons-material/ShuffleOn";

import { HttpMainApi } from "../../interface/main-api";

declare global {
  interface Window {
    IVSPlayer: any;
  }
}

interface propsType {
  index: number;
  playList: any;
  videoKey: string;
  priority: boolean;
  callback: any;
}

const mainApi = new HttpMainApi();

const PlayListVideo = (props: propsType) => {
  const navigate = useNavigate();
  const [videoInfo, setVideoInfo] = useState<any>({});

  useEffect(() => {
    get_video_info();
    return () => {
      setVideoInfo({});
    };
  }, []);

  // 비디오 정보 조회
  const get_video_info = async () => {
    const param: any = {
      command: "get_video_info",
      video_key: props.videoKey,
    };

    const res = await mainApi.post(param);
    if (res.code === "200") {
      setVideoInfo(res.response.video_info);
    } else {
      console.error(">>>", res.response.error_msg);
    }
  };

  // 상세정보 페이지 이동
  const goDetail = useCallback(() => {
    navigate(`/detail/${props.videoKey}`);
  }, []);

  return (
    <TableRow key={videoInfo.sk} sx={{ "& > *": { borderBottom: "unset" } }}>
      <TableCell align="left">
        <Stack direction={"column"} spacing={0}>
          <IconButton
            sx={{ p: 0 }}
            color="primary"
            disabled={props.index === 0}
            onClick={() => {
              props.callback({ command: "forward_index", index: props.index });
            }}
          >
            <ExpandLessIcon sx={{ fontSize: "1.3rem" }} />
          </IconButton>
          <IconButton
            sx={{ p: 0 }}
            color="primary"
            disabled={props.index === props.playList.length - 1}
            onClick={() => {
              props.callback({ command: "backward_index", index: props.index });
            }}
          >
            <ExpandMoreIcon sx={{ fontSize: "1.3rem" }} />
          </IconButton>
        </Stack>
      </TableCell>
      <TableCell align="left" width={40}>
        <CardMedia
          component="img"
          image={videoInfo.thumb === "custom" ? videoInfo.custom_tumbnail : videoInfo.thumbnail_url}
          sx={{ objectFit: "contain", cursor: "pointer" }}
          onClick={goDetail}
        />
      </TableCell>
      <TableCell component="th" scope="row">
        {videoInfo.display_title}
      </TableCell>
      <TableCell align="right">
        <Tooltip title={props.priority ? "현재상태 : 우선순위" : "현재상태 : 셔플"}>
          <IconButton
            onClick={() => {
              props.callback({ command: "change_priority", index: props.index, value: !props.priority });
            }}
          >
            <ShuffleOnIcon color={props.priority ? "inherit" : "primary"} />
          </IconButton>
        </Tooltip>
      </TableCell>
      <TableCell align="right">
        <Tooltip title="제거">
          <IconButton
            onClick={() => {
              props.callback({ command: "delete_play_list", video_key: props.videoKey });
            }}
          >
            <PlaylistRemoveIcon color="warning" />
          </IconButton>
        </Tooltip>
      </TableCell>
    </TableRow>
  );
};

export default PlayListVideo;
