import { useEffect, useRef, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useWindowSize } from "../../utils/useWindowSize";

import {
  Box,
  Grid,
  Stack,
  Divider,
  Typography,
  Button,
  Paper,
  TextField,
  Tooltip,
  IconButton,
  Table,
  TableContainer,
  TableBody,
  CardMedia,
} from "@mui/material";
import ShareIcon from "@mui/icons-material/Share";
import DeleteIcon from "@mui/icons-material/Delete";
import LinkIcon from "@mui/icons-material/Link";
import SaveIcon from "@mui/icons-material/Save";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";

import { HttpMainApi } from "../../interface/main-api";
import DrawerVideoList from "./DrawerVideoList";
import PlayListVideo from "./PlayListVideo";

import { userState } from "../../interface/MainInterface";
import LoadingCircle from "../../utils/LoadingCircle";
import Toast from "../../utils/Toast";

import PopUploadThumbViaApi from "./PopUploadThumbViaApi";

interface propsType {
  userState: userState;
}

const mainApi = new HttpMainApi();

const ShortDetail = (props: propsType) => {
  const windowSize = useWindowSize();
  const nvaigate = useNavigate();
  const { shortSeq } = useParams();
  const toastRef: any = useRef();
  const sInfoRef: any = useRef();
  const frameRef: any = useRef();
  const drawerAddVideoRef: any = useRef();
  const popUploadThumbRef: any = useRef();
  const [isLoading, setIsLoading] = useState(false);

  const [shortInfo, setShortInfo] = useState<any>({});
  const [playList, setPlayList] = useState<any>([]);
  const [realPlayList, setRealPlayList] = useState<any>([]);

  const [linkUrl, setLinkUrl] = useState("");
  const [code, setCode] = useState("");
  const [ebdCode, setEbdCode] = useState("");

  const [title, setTitle] = useState("");
  const [desc, setDesc] = useState("");
  const [thumbImageUrl, setThumbImageUrl] = useState("");

  const onMessageArrive = (e: MessageEvent) => {
    if (e.origin === "https://thd-play.livehyundai.com" && e.data.msg !== undefined) {
      // 창닫기
      if (e.data.msg === "closeWindow") {
        window.alert("창닫기 명령 도착");
        window.postMessage({ msg: "closed window" }, "https://thd-play.livehyundai.com");
      }

      // 상품클릭
      if (e.data.msg === "showProdDetail") {
        window.open(`https://www.thehyundai.com/front/pda/itemPtc.thd?slitmCd=${e.data.productId}`, "_blank");
      }

      // 더보기 클릭
      if (e.data.msg === "campagin") {
        window.open(e.data.url, "_blank");
      }
    }
  };

  useEffect(() => {
    window.scrollTo({ top: 0 });
    window.addEventListener("message", onMessageArrive);
    return () => {
      setIsLoading(false);
      setShortInfo({});
      setPlayList([]);
      setRealPlayList([]);
      setLinkUrl("");
      setCode("");
      setEbdCode("");
      setTitle("");
      setDesc("");
      window.removeEventListener("message", onMessageArrive);
    };
  }, []);

  // 비디오 정보 조회
  const get_short_info = async () => {
    if (shortSeq === undefined || shortSeq === "") return;
    const param: any = {
      command: "get_short_info",
      short_key: shortSeq,
      userVipCode: "", //player에서만 사용
    };

    setCode("");
    setPlayList([]);
    const res = await mainApi.post(param);
    if (res.code === "200") {
      setShortInfo(res.response.short_info);
      sInfoRef.current = res.response.short_info; // 이벤트 핸드러내 메소드에서는 state에 접근할 수 가 없음...
      document.title = res.response.short_info.title; // 브라우저 타이틀 변경
      setTitle(res.response.short_info.title);
      setDesc(res.response.short_info.short_desc !== undefined ? res.response.short_info.short_desc : "");
      setThumbImageUrl(res.response.short_info.thumbImageUrl !== undefined ? res.response.short_info.thumbImageUrl : "");
      let temp_list: any = [];

      if (res.response.short_info.play_list !== undefined) {
        setRealPlayList(res.response.short_info.play_list);
        for (const play_info of res.response.short_info.play_list) {
          temp_list.push(play_info.video_key);
        }
        setPlayList(temp_list);
      }
      makeParam(res.response.short_info);
    }
  };

  useEffect(() => {
    get_short_info();
  }, []);

  // Embed, Copy Link code generate
  const makeParam = (info: any) => {
    let url = `${process.env.REACT_APP_PLAYER_DOMAIN_NAME}/short/${info.sk}/0`;
    let code = `<div style="padding:calc(100vh - 170px) 0 0 0;position:relative;"><iframe name="short_frame" src="${process.env.REACT_APP_PLAYER_DOMAIN_NAME}/short/${info.sk}/0?control" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" style="position:absolute;top:0;left:0;width:100%;height:100%;" title="${info.title}"></iframe></div>`;
    let ebdCode = `<div style="padding:177.78% 0 0 0;position:relative;"><iframe src="${process.env.REACT_APP_PLAYER_DOMAIN_NAME}/short/${info.sk}/0" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" style="position:absolute;top:0;left:0;width:100%;height:100%;" title="${info.title}"></iframe></div>`;

    setLinkUrl(url);
    setCode(code);
    setEbdCode(ebdCode);
  };

  // 숏폼 타이틀 수정
  const update_short_title = async () => {
    // 다른 프로세스에서도 데이터를 업데이트 하는 건들이 많아서 전체 put보다는 단일 update로 작성
    const param: any = {
      command: "update_short_title",
      pk: shortInfo.pk,
      sk: shortInfo.sk,
      title: title,
    };
    setIsLoading(true);
    const res = await mainApi.post(param);
    if (res.code === "200") {
      get_short_info();
      toastRef.current?.toast("Title update complete", "success", 4000, { vertical: "top", horizontal: "center" });
    } else {
      console.error(res.response.error_msg);
      toastRef.current?.toast("" + res.response.error_msg, "error", 4000, { vertical: "top", horizontal: "center" });
    }
    setIsLoading(false);
  };

  // 숏폼 설명 수정
  const update_short_desc = async () => {
    // 다른 프로세스에서도 데이터를 업데이트 하는 건들이 많아서 전체 put보다는 단일 update로 작성
    const param: any = {
      command: "update_short_desc",
      pk: shortInfo.pk,
      sk: shortInfo.sk,
      short_desc: desc,
    };
    setIsLoading(true);
    const res = await mainApi.post(param);
    if (res.code === "200") {
      get_short_info();
      toastRef.current?.toast("Description update complete", "success", 4000, { vertical: "top", horizontal: "center" });
    } else {
      console.error(res.response.error_msg);
      toastRef.current?.toast("" + res.response.error_msg, "error", 4000, { vertical: "top", horizontal: "center" });
    }
    setIsLoading(false);
  };

  // 솟폼 삭제
  const delete_short_play_list = async () => {
    if (!window.confirm("플레이리스트를 삭제합니다.\n삭제한 플레이리스트는 복구할 수 없습니다.")) return;
    const param: any = {
      command: "delete_short_play_list",
      short_info: {
        key: shortInfo.sk,
      },
      user_info: {
        id: props.userState.id,
      },
    };
    setIsLoading(true);
    const res = await mainApi.post(param);
    if (res.code === "200") {
      nvaigate("/mngShort");
    } else {
      console.error(res.response.error_msg);
      toastRef.current?.toast("" + res.response.error_msg, "error", 4000, { vertical: "top", horizontal: "center" });
    }
    setIsLoading(false);
  };

  const delete_video_from_short = async (videoKey: string) => {
    if (!window.confirm("해당 비디오를 플레이리스트에서 삭제합니다.")) return;
    const param: any = {
      command: "delete_video_from_short",
      short_key: shortInfo.sk,
      video_key: videoKey,
    };
    setIsLoading(true);
    const res = await mainApi.post(param);
    if (res.code === "200") {
      get_short_info();
    } else {
      console.error(res.response.error_msg);
      toastRef.current?.toast("" + res.response.error_msg, "error", 4000, { vertical: "top", horizontal: "center" });
    }
    setIsLoading(false);
  };

  // 숏폼 플레이리스트 순번 변경
  const update_short_play_list_sort = async (newList: any) => {
    // 다른 프로세스에서도 데이터를 업데이트 하는 건들이 많아서 전체 put보다는 단일 update로 작성
    const param: any = {
      command: "update_short_play_list_sort",
      pk: shortInfo.pk,
      sk: shortInfo.sk,
      play_list: newList,
    };
    setIsLoading(true);
    const res = await mainApi.post(param);
    if (res.code === "200") {
      // get_short_info();
      // toastRef.current?.toast("Description update complete", "success", 4000, { vertical: "top", horizontal: "center" });
    } else {
      console.error(res.response.error_msg);
      toastRef.current?.toast("" + res.response.error_msg, "error", 4000, { vertical: "top", horizontal: "center" });
    }
    setIsLoading(false);
  };

  return (
    <>
      <Box sx={{ p: 0, height: "100%", backgroundColor: "#eef1f4" }}>
        <Stack direction={"column"} spacing={0} sx={{ backgroundColor: "#eef1f4" }}>
          <Box className="video-detail-header-root">
            <Typography variant="h5" gutterBottom className="my-account-header-title">
              {shortInfo ? shortInfo.title : "플레이리스트 관리"}
            </Typography>
          </Box>
          <Divider />
          <Box className="video-detail-content-root">
            <Stack direction={"column"} spacing={2}>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={4}>
                  <Box ref={frameRef} dangerouslySetInnerHTML={{ __html: code }} />
                </Grid>
                <Grid item xs={12} lg={8}>
                  <Box sx={{ pr: { xs: 0, sm: 2, xl: 4 } }}>
                    <Paper sx={{ p: 2, borderRadius: "7px" }} elevation={1}>
                      <Stack direction={"column"} spacing={2}>
                        {/* 상단 버튼 영역 */}
                        <Box className="video-detail-info-row" sx={{ overflow: "auto" }}>
                          <Stack direction={"row"} spacing={1} sx={{ width: "100%" }}>
                            <Box sx={{ display: "flex", justifyContent: "flex-start", minWidth: "120px" }}>
                              <Tooltip title="플레이리스트 삭제">
                                <Button
                                  variant="contained"
                                  onClick={() => {
                                    // delete short
                                    delete_short_play_list();
                                  }}
                                  color="error"
                                  sx={{ height: "60px" }}
                                >
                                  <DeleteIcon />
                                </Button>
                              </Tooltip>
                            </Box>
                            <Box sx={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
                              <Stack direction={"row"} spacing={{ xs: 1, sm: 4 }}>
                                <Tooltip title="비디오 추가">
                                  <Button
                                    variant="outlined"
                                    onClick={() => {
                                      drawerAddVideoRef.current.open();
                                    }}
                                    sx={{ height: "60px" }}
                                  >
                                    <PlaylistAddIcon />
                                  </Button>
                                </Tooltip>
                                <Tooltip title="비디오 보기(새창)">
                                  <Button
                                    variant="contained"
                                    onClick={() => {
                                      // 비디오 보기
                                      window.open(linkUrl, "_blank");
                                    }}
                                    sx={{ height: "60px" }}
                                  >
                                    <OpenInNewIcon />
                                  </Button>
                                </Tooltip>

                                <CopyToClipboard
                                  text={linkUrl}
                                  onCopy={() => {
                                    toastRef.current?.toast("비디오 링크를 복사했습니다.", "success", 3000, {
                                      vertical: "top",
                                      horizontal: "center",
                                    });
                                  }}
                                >
                                  <Tooltip title="비디오 링크 복사">
                                    <Button variant="contained" sx={{ height: "60px" }}>
                                      <LinkIcon />
                                    </Button>
                                  </Tooltip>
                                </CopyToClipboard>
                                <CopyToClipboard
                                  text={ebdCode}
                                  onCopy={() => {
                                    toastRef.current?.toast("임베딩 코드를 복사했습니다.", "success", 3000, {
                                      vertical: "top",
                                      horizontal: "center",
                                    });
                                  }}
                                >
                                  <Tooltip title="임베딩 코드">
                                    <Button variant="contained" sx={{ height: "60px" }}>
                                      <ShareIcon />
                                    </Button>
                                  </Tooltip>
                                </CopyToClipboard>
                              </Stack>
                            </Box>
                          </Stack>
                        </Box>
                        <Divider />
                        {/* 타이틀 */}
                        <Box className="video-detail-info-row">
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={2} lg={3} xl={2}>
                              <Typography className="video-detail-info-title">Title</Typography>
                            </Grid>
                            <Grid item xs={12} sm={10} lg={9} xl={10}>
                              <Stack direction={"row"} spacing={1}>
                                <TextField
                                  id="txtTitle"
                                  value={title}
                                  placeholder="플레이리스트의 제목을 입력하세요."
                                  fullWidth
                                  onChange={(e) => {
                                    setTitle(e.target.value);
                                  }}
                                  size="small"
                                  type="search"
                                  autoComplete="off"
                                  inputProps={{ enterKeyHint: "Enter" }}
                                />
                                <Box>
                                  <Tooltip title="타이틀 저장">
                                    <IconButton
                                      onClick={() => {
                                        update_short_title();
                                      }}
                                    >
                                      <SaveIcon color="primary" />
                                    </IconButton>
                                  </Tooltip>
                                </Box>
                              </Stack>
                            </Grid>
                          </Grid>
                        </Box>
                        <Divider />
                        {/* 비디오 설명 */}
                        {/* <Box className="video-detail-info-row">
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={2} lg={3} xl={2}>
                              <Typography className="video-detail-info-title">Description</Typography>
                            </Grid>
                            <Grid item xs={12} sm={10} lg={9} xl={10}>
                              <Stack direction={"row"} spacing={1}>
                                <TextField
                                  id="txtDesc"
                                  value={desc}
                                  placeholder="플레이리스트의 설명을 입력하세요."
                                  fullWidth
                                  multiline
                                  rows={4}
                                  onChange={(e) => {
                                    setDesc(e.target.value);
                                  }}
                                  size="small"
                                  type="search"
                                  autoComplete="off"
                                  inputProps={{ enterKeyHint: "Enter" }}
                                />
                                <Box>
                                  <Tooltip title="플레이리스트 설명 저장">
                                    <IconButton
                                      onClick={() => {
                                        update_short_desc();
                                      }}
                                    >
                                      <SaveIcon color="primary" />
                                    </IconButton>
                                  </Tooltip>
                                </Box>
                              </Stack>
                            </Grid>
                          </Grid>
                        </Box>
                        <Divider /> */}
                        {/* 비디오 설명 */}
                        {/* 썸네일 이미지 */}
                        <Box className="video-detail-info-row">
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={2} lg={3} xl={2}>
                              <Typography className="video-detail-info-title">Thumbnail</Typography>
                            </Grid>
                            <Grid item xs={12} sm={10} lg={9} xl={10}>
                              <Tooltip title="썸네일 교체">
                                <Button
                                  onClick={() => {
                                    popUploadThumbRef.current.open();
                                  }}
                                  sx={{ p: 0 }}
                                >
                                  <CardMedia
                                    component="img"
                                    image={thumbImageUrl}
                                    sx={{
                                      height: "160px",
                                      objectFit: "fit",
                                      borderRadius: "4px",
                                    }}
                                  />
                                </Button>
                              </Tooltip>
                            </Grid>
                          </Grid>
                        </Box>
                        <Divider />
                        <Box className="video-detail-info-row">
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={2} lg={3} xl={2}>
                              <Typography className="video-detail-info-title">Playlist</Typography>
                              <Typography className="video-detail-info-title">{`(${playList.length})`}</Typography>
                            </Grid>
                            <Grid item xs={12} sm={10} lg={9} xl={10}>
                              <Box sx={{ width: "100%" }}>
                                <TableContainer
                                  component={Paper}
                                  elevation={5}
                                  sx={{ height: `calc(${windowSize.height}px - 560px)`, overflowY: "auto" }}
                                >
                                  <Table className="data-table">
                                    <TableBody>
                                      {realPlayList.map((playInfo: any, index: number) => (
                                        <PlayListVideo
                                          key={playInfo.video_key + "" + index}
                                          index={index}
                                          playList={playList}
                                          videoKey={playInfo.video_key}
                                          priority={playInfo.priority}
                                          callback={(payload: any) => {
                                            if (payload.command === "delete_play_list") {
                                              delete_video_from_short(payload.video_key);
                                            }
                                            if (payload.command === "forward_index") {
                                              let newList: any = [...realPlayList];
                                              newList.splice(index, 1);
                                              newList.splice(index - 1, 0, playInfo);
                                              setRealPlayList(newList);
                                              update_short_play_list_sort(newList);
                                            }
                                            if (payload.command === "backward_index") {
                                              let newList: any = [...realPlayList];
                                              newList.splice(index, 1);
                                              newList.splice(index + 1, 0, playInfo);
                                              setRealPlayList(newList);
                                              update_short_play_list_sort(newList);
                                            }
                                            if (payload.command === "change_priority") {
                                              let newList: any = [...realPlayList];
                                              newList[payload.index].priority = payload.value;
                                              setRealPlayList(newList);
                                              update_short_play_list_sort(newList);
                                            }
                                          }}
                                        />
                                      ))}
                                    </TableBody>
                                  </Table>
                                </TableContainer>
                              </Box>
                            </Grid>
                          </Grid>
                        </Box>
                      </Stack>
                    </Paper>
                  </Box>
                </Grid>
              </Grid>
            </Stack>
          </Box>
        </Stack>
      </Box>
      <LoadingCircle loading={isLoading} />
      <Toast ref={toastRef} />
      {shortInfo.sk !== undefined && (
        <PopUploadThumbViaApi
          ref={popUploadThumbRef}
          userState={props.userState}
          short_key={shortInfo.sk}
          callback={async (payload: any) => {
            if (payload.command === "upload_finish") {
              const param: any = {
                command: "update_short_thumbnail",
                pk: shortInfo.pk,
                sk: shortInfo.sk,
                thumbImageUrl: payload.path,
              };

              const res = await mainApi.post(param);
              if (res.code === "200") {
                get_short_info();
              } else {
                console.error(res.response.error_msg);
                toastRef.current?.toast("" + res.response.error_msg, "error", 4000, { vertical: "top", horizontal: "center" });
              }
            }
          }}
        />
      )}
      {shortInfo.sk !== undefined && (
        <>
          <DrawerVideoList
            ref={drawerAddVideoRef}
            userState={props.userState}
            shortInfo={shortInfo}
            playList={playList}
            callback={(payload: any) => {
              if (payload.command === "finish_add_video") {
                toastRef.current?.toast("플레이리스트에 비디오를 추가했습니다.", "success", 4000, {
                  vertical: "top",
                  horizontal: "center",
                });
                get_short_info();
              }
            }}
          />
        </>
      )}
    </>
  );
};

export default ShortDetail;
