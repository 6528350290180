import { useEffect, useRef, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { saveAs } from "file-saver";

import {
  Box,
  Stack,
  Typography,
  Paper,
  Card,
  CardActionArea,
  CardContent,
  Avatar,
  SpeedDial,
  SpeedDialAction,
  CardMedia,
} from "@mui/material";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import ShareIcon from "@mui/icons-material/Share";
import DownloadIcon from "@mui/icons-material/Download";
import DeleteIcon from "@mui/icons-material/Delete";

import { HttpMainApi } from "../../interface/main-api";
import { HttpStatisticApi } from "../../interface/statistic-api";

import { CommonUtils } from "../../utils/common_utils";

import { userState } from "../../interface/MainInterface";
import LoadingCircle from "../../utils/LoadingCircle";
import Toast from "../../utils/Toast";

import PopEmbedVideo from "./PopEmbedVideo";

declare global {
  interface Window {
    IVSPlayer: any;
  }
}

interface propsType {
  userState: userState;
  videoInfo: any;
  callback: any;
}

const mainApi = new HttpMainApi();
const statisticApi = new HttpStatisticApi();
const utils = new CommonUtils();

const CardVideo = (props: propsType) => {
  const navigate = useNavigate();
  const toastRef: any = useRef();
  const posterRef: any = useRef();
  const popEmbedVideoRef: any = useRef();

  const [isLoading, setIsLoading] = useState(false);
  const [videoInfo, setVideoInfo] = useState<any>({});

  const [totalView, setTotalView] = useState(0);
  const [totalLike, setTotalLike] = useState(0);

  const [open, setOpen] = useState(false);

  useEffect(() => {
    get_my_video_info();
    get_statistic_info();
    return () => {
      setIsLoading(false);
      setVideoInfo({});
      setOpen(false);
      setTotalView(0);
      setTotalLike(0);
    };
  }, []);

  // 스피드 다이얼 버튼 세팅
  const actions = [
    { icon: <ShareIcon />, name: "Share" },
    { icon: <DownloadIcon />, name: "Download" },
    { icon: <DeleteIcon />, name: "Delete" },
  ];

  // 비디오 정보 조회
  const get_my_video_info = async () => {
    const param: any = {
      command: "get_my_video_info",
      pk: props.videoInfo.pk,
      sk: props.videoInfo.sk,
    };

    const res = await mainApi.post(param);
    console.log("res : ", res);
    if (res.code === "200") {
      setVideoInfo(res.response.video_info);

      // 썸네일 처리
      const video_default_thumb = res.response.video_info.video_thumb === undefined ? "video" : res.response.video_info.video_thumb;
      const tumbnal = res.response.video_info.thumbnail_url === undefined ? "" : res.response.video_info.thumbnail_url;
      const custom_tumbnal = res.response.video_info.custom_tumbnail === undefined ? "" : res.response.video_info.custom_tumbnail;

      if (tumbnal !== "" || custom_tumbnal !== "") {
        if (video_default_thumb === "video") {
          posterRef.current = tumbnal;
        } else {
          posterRef.current = custom_tumbnal;
        }
      } else {
        posterRef.current = "/images/no-image.jpg";
      }
    } else {
      console.error(">>>", res.response.error_msg);
    }
  };

  // 비디오 통계 데이터 조회
  const get_statistic_info = async () => {
    const param: any = {
      command: "get_statistic_info",
      video_key: props.videoInfo.sk,
    };

    const res = await statisticApi.post(param);
    if (res.code === "200") {
      if (res.response.statistic_info?.total_views !== undefined) setTotalView(res.response.statistic_info.total_views);
      if (res.response.statistic_info?.total_likes !== undefined) setTotalLike(res.response.statistic_info.total_likes);
    }
  };

  // 스피드 다이얼 액션
  const fncAction = (actionName: string) => {
    if (actionName === "Download") {
      // 비디오 다운로드
      fncDownloadAttach();
    }
    if (actionName === "Delete") {
      // 비디오 삭제
      delete_my_video();
    }

    if (actionName === "View") {
      // 비디오 보기
      window.open(`${process.env.REACT_APP_PLAYER_DOMAIN_NAME}/video/${videoInfo.sk}`, "_blank");
    }

    if (actionName === "Share") {
      // Embed
      popEmbedVideoRef.current.open();
    }
  };

  // 비디오 다운로드
  const fncDownloadAttach = async () => {
    setIsLoading(true);
    await fetch(videoInfo.origin_file_url, { method: "GET" })
      .then((res) => {
        return res.blob();
      })
      .then((blob) => {
        saveAs(blob, `${videoInfo.file_name}`);
      })
      .catch((err) => {
        console.error("err : ", err);
      });
    setIsLoading(false);
  };

  // 비디오 삭제
  const delete_my_video = async () => {
    if (!window.confirm("비디오를 삭제합니다.\n삭제한 비디오는 복구할 수 없습니다.")) return;
    const param: any = {
      command: "delete_video",
      video_info: {
        key: videoInfo.sk,
      },
      user_info: {
        id: props.userState.id,
      },
    };
    setIsLoading(true);
    const res = await mainApi.post(param);
    if (res.code === "200") {
      props.callback({ command: "delete_video_finish" });
    } else {
      console.error(res.response.error_msg);
      toastRef.current?.toast("" + res.response.error_msg, "error", 4000, { vertical: "top", horizontal: "center" });
    }
    setIsLoading(false);
  };

  // 상세정보 페이지 이동
  const goDetail = useCallback(() => {
    navigate(`/detail/${props.videoInfo.sk}`);
  }, []);

  return (
    <>
      <Box sx={{ p: 0, height: "100%" }}>
        <Card sx={{ position: "relative" }}>
          <CardActionArea sx={{ position: "relative" }} onClick={goDetail}>
            <CardMedia
              component="img"
              image={posterRef.current}
              onError={(e) => {
                e.currentTarget.src = "/images/no-image.jpg";
              }}
            />
            <Paper sx={{ position: "absolute", top: "5px", left: "5px", p: 0.5, color: "#ffffff", backgroundColor: "#00000080" }}>
              {`view : ${utils.numericComma(totalView)} / like : ${utils.numericComma(totalLike)}`}
            </Paper>
          </CardActionArea>
          <Box sx={{ height: 320, transform: "translateZ(0px)", flexGrow: 1, position: "absolute", right: -7, bottom: 73 }}>
            <SpeedDial
              ariaLabel="SpeedDial controlled open example"
              sx={{ position: "absolute", bottom: 35, right: 16 }}
              icon={<SpeedDialIcon />}
              onClose={() => {
                setOpen(false);
              }}
              onOpen={() => {
                setOpen(true);
              }}
              open={open}
              direction="left"
            >
              {actions.map((action) => (
                <SpeedDialAction
                  key={action.name}
                  icon={action.icon}
                  tooltipTitle={action.name}
                  onClick={() => {
                    fncAction(action.name);
                  }}
                />
              ))}
            </SpeedDial>
          </Box>
          <CardContent sx={{ p: 0, pl: 1, pr: 1, pb: "8px !important" }}>
            <Stack direction={"column"} spacing={1}>
              <Box>
                <Typography
                  sx={{
                    fontWeight: "800 !important",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    lineHeight: "40px",
                  }}
                >
                  {videoInfo.display_title}
                </Typography>
              </Box>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Stack direction={"row"} spacing={1}>
                  <Avatar sx={{ bgcolor: "#9d97c7", width: "36px", height: "36px" }}>{videoInfo.pk?.split("#")[1][0]}</Avatar>
                  <Typography
                    sx={{
                      marginTop: "7px !important",
                      fontSize: "14px !important",
                      color: "#7a7a7a !important",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {videoInfo.pk?.split("#")[1]}
                  </Typography>
                </Stack>
                <Typography sx={{ fontSize: "11px !important", color: "#00000080 !important", lineHeight: "39px" }}>{`${dayjs(
                  videoInfo.create_time
                ).format("YYYY-MM-DD")}`}</Typography>
              </Box>
            </Stack>
          </CardContent>
        </Card>
      </Box>
      <LoadingCircle loading={isLoading} />
      <Toast ref={toastRef} />
      <PopEmbedVideo ref={popEmbedVideoRef} userState={props.userState} videoInfo={props.videoInfo} callback={(payload: any) => {}} />
    </>
  );
};

export default CardVideo;
