import { useCallback, useEffect, useRef, useState, forwardRef, useImperativeHandle } from "react";

import { Box, Stack, Modal, Typography, IconButton, Tooltip, TextField, Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import Toast from "../../utils/Toast";
import LoadingCircle from "../../utils/LoadingCircle";

import { userState } from "../../interface/MainInterface";

import { HttpMainApi } from "../../interface/main-api";

interface propsType {
  userState: userState;
  callback: any;
}

const mainApi = new HttpMainApi();

const PopAdminCreateUser = (props: propsType, ref: any) => {
  const toastRef: any = useRef();

  const [openModal, setOpenModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [userEmail, setUserEmail] = useState("");
  const [userPwd, setUserPwd] = useState("");
  const [userPwdConfirm, setUserPwdConfirm] = useState("");
  const [userName, setUserName] = useState("");
  const [userCompany, setUserCompany] = useState("");

  useEffect(() => {
    return () => {
      setOpenModal(false);
      setUserEmail("");
      setUserPwd("");
      setUserPwdConfirm("");
      setUserName("");
      setUserCompany("");
    };
  }, []);

  const open = useCallback((_videoKey: string) => {
    setOpenModal(true);
  }, []);

  const close = () => {
    setUserEmail("");
    setUserPwd("");
    setUserPwdConfirm("");
    setUserName("");
    setUserCompany("");
    props.callback({ command: "close" });
    setOpenModal(false);
  };

  const admin_create_user = async () => {
    if (userEmail === "" || userEmail === undefined) {
      toastRef.current?.toast("이메일을 입력하세요.", "error", 4000, { vertical: "top", horizontal: "center" });
      return;
    }
    if (userPwd === "" || userPwd === undefined) {
      toastRef.current?.toast("패스워드를 입력하세요.", "error", 4000, { vertical: "top", horizontal: "center" });
      return;
    }
    if (userPwd !== userPwdConfirm) {
      toastRef.current?.toast("패스워드가 일치하지 않습니다.", "error", 4000, { vertical: "top", horizontal: "center" });
      return;
    }
    if (userName === "" || userName === undefined) {
      toastRef.current?.toast("이름을 입력하세요.", "error", 4000, { vertical: "top", horizontal: "center" });
      return;
    }

    setIsLoading(true);
    const param: any = {
      command: "admin_create_user",
      email: userEmail,
      password: userPwd,
      name: userName,
      company: userCompany,
    };

    const res = await mainApi.post(param);
    if (res.code === "200") {
      props.callback({ command: "success" });
      close();
    } else {
      if (res.code === "201" || res.code === "202") alert(`${res.response.error_msg}`);
      else alert(`알수 없는 에러가 발생했습니다.\n- CODE : ${res.code}\n- message : ${res.response.error_msg}`);
    }
    setIsLoading(false);
  };

  // 부모 Component에서 접근 가능하도록 함수 전달.
  useImperativeHandle(ref, () => ({
    open,
  }));

  return (
    <>
      <Modal
        open={openModal}
        onClose={() => {
          close();
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: { xs: "80%", md: "50%", lg: "40%" },
            maxWidth: "90%",
            maxHeight: "90%",
            bgcolor: "background.paper",
            border: "0px solid #000",
            borderRadius: "1rem",
            boxShadow: 24,
            p: 3,
            zIndex: 990,
          }}
        >
          <Stack direction="column" spacing={2}>
            <Stack direction="row" spacing={2} sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography gutterBottom sx={{ lineHeight: "40px", fontWeight: "800 !important" }}>
                {"사용자 추가(관리자 권한)"}
              </Typography>
              <Tooltip title="창닫기">
                <IconButton aria-label="close" onClick={close}>
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            </Stack>
            <Stack spacing={2} direction="column">
              <TextField
                id="signUpEmail"
                value={userEmail}
                placeholder="이메일을 입력해주세요."
                variant="outlined"
                autoFocus
                fullWidth
                required
                type="search"
                autoComplete="off"
                inputProps={{ enterKeyHint: "Enter", style: { padding: "12px 16px 10px 16px" } }}
                onChange={(e) => {
                  setUserEmail(e.target.value);
                }}
              />
              <TextField
                id="signUpPwd"
                value={userPwd}
                placeholder="패스워드를 입력해주세요."
                variant="outlined"
                fullWidth
                required
                type="password"
                autoComplete="current-password"
                inputProps={{ autoComplete: "new-password", enterKeyHint: "Enter", style: { padding: "12px 16px 10px 16px" } }}
                onChange={(e) => {
                  setUserPwd(e.target.value);
                }}
              />
              <TextField
                id="signUpPwdComfirm"
                value={userPwdConfirm}
                placeholder="패스워드를 다시 입력해주세요."
                variant="outlined"
                fullWidth
                required
                type="password"
                autoComplete="current-password"
                inputProps={{ autoComplete: "new-password", enterKeyHint: "Enter", style: { padding: "12px 16px 10px 16px" } }}
                onChange={(e) => {
                  setUserPwdConfirm(e.target.value);
                }}
              />
              <TextField
                id="signUpName"
                value={userName}
                placeholder="이름을 입력해주세요."
                variant="outlined"
                fullWidth
                required
                type="search"
                autoComplete="off"
                inputProps={{ enterKeyHint: "Enter", style: { padding: "12px 16px 10px 16px" } }}
                onChange={(e) => {
                  setUserName(e.target.value);
                }}
              />
              <TextField
                id="signUpCompany"
                value={userCompany}
                placeholder="회사나 부서명을 입력해주세요."
                variant="outlined"
                fullWidth
                required
                type="search"
                autoComplete="off"
                inputProps={{ enterKeyHint: "Enter", style: { padding: "12px 16px 10px 16px" } }}
                onChange={(e) => {
                  setUserCompany(e.target.value);
                }}
              />
              <Button variant="contained" onClick={admin_create_user}>
                사용자 추가
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Modal>
      <Toast ref={toastRef} />
      <LoadingCircle loading={isLoading} />
    </>
  );
};

export default forwardRef(PopAdminCreateUser);
