import { useEffect, useRef, useState } from "react";

import { Grid } from "@mui/material";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";

import { userState } from "../../interface/MainInterface";
import LoadingCircle from "../../utils/LoadingCircle";
import Toast from "../../utils/Toast";

import ChangePwd from "./ChangePwd";

interface propsType {
  userState: userState;
}

const Settings = (props: propsType) => {
  const toastRef: any = useRef();
  const chPwdRef: any = useRef();
  const [isLoading, setIsLoading] = useState(false);

  const openChangePwd = () => {
    chPwdRef.current.open(true);
  };

  useEffect(() => {
    return () => {
      setIsLoading(false);
    };
  }, []);

  return (
    <>
      <Box sx={{ p: 0, height: "100%" }}>
        <Stack direction={"column"} spacing={0}>
          <Box className="my-account-header-root">
            <Typography variant="h5" gutterBottom className="my-account-header-title">
              {props.userState.name} / Settings
            </Typography>
          </Box>
          <Divider />
          <Box className="my-account-content-root">
            <Box className="apikey-content-header">
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography gutterBottom className="my-account-content-sub-title">
                      Manage Settings here
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box sx={{ display: "flex", width: "100%", justifyContent: "flex-end" }}>
                    <Button variant="contained" onClick={openChangePwd}>
                      Change Password
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box>
              <Paper sx={{ p: 3, borderRadius: "4px" }} elevation={3}>
                <Stack direction="column" spacing={2}>
                  <Box className="my-account-setting-detail-row">
                    <Typography className="setting-detail-sub-title">ID</Typography>
                    <Typography>{props.userState.id}</Typography>
                  </Box>
                  <Divider />
                  <Box className="my-account-setting-detail-row">
                    <Typography className="setting-detail-sub-title">Name</Typography>
                    <Typography>{props.userState.name}</Typography>
                  </Box>
                  <Divider />
                  <Box className="my-account-setting-detail-row">
                    <Typography className="setting-detail-sub-title">Company</Typography>
                    <Typography>{props.userState.company}</Typography>
                  </Box>
                </Stack>
              </Paper>
            </Box>
          </Box>
        </Stack>
      </Box>
      <LoadingCircle loading={isLoading} />
      <Toast ref={toastRef} />
      <ChangePwd ref={chPwdRef} userState={props.userState} />
    </>
  );
};

export default Settings;
