import { useEffect, useState } from "react";
import Chart from "chart.js/auto";
import { CategoryScale, TooltipItem } from "chart.js";
import { Line } from "react-chartjs-2";

interface propsType {
  dataRows: any;
  chartTitle: string;
  labelTitle: string;
  xName: string;
  yName: string;
  lineColor: string;
  convertFormat: any;
  displayX: boolean;
  displayY: boolean;
  yStep: number;
}

interface IGridCols {
  label: string;
  value: number;
}

Chart.register(CategoryScale);

const LineTimeChart = (props: propsType) => {
  const [chartData, setChartData] = useState<any>();

  const convertSecondsToTime = (seconds: number) => {
    const addZero = (num: number) => {
      return ((num < 10) ? '0' : '') + num
    }

    if(seconds < 61) {
      return '00:00:' + addZero(seconds)
    }

    const hours = Math.floor(seconds / 3600)
    const mins = Math.floor((seconds - hours * 3600) / 60)
    const secs = seconds - hours * 3600 - mins * 60
    
    return addZero(hours) + ':' + addZero(mins) + ':' + addZero(secs)

  }

  useEffect(() => {
    if (props.dataRows.length > 0) {
      let newDataRows = [...props.dataRows];
      setChartData({
        labels: newDataRows.map((data: IGridCols) => props.convertFormat(data.label)),
        datasets: [
          {
            label: props.labelTitle,
            data: props.dataRows.map((data: IGridCols) => data.value),
            borderColor: props.lineColor,
          },
        ],
      });
    } else {
      setChartData({
        labels: [],
        datasets: [{ label: props.labelTitle, data: [], borderColor: props.lineColor }],
      });
    }
  }, [props.dataRows]);

  useEffect(() => {
    return () => {
      // 메모리 누수를 방지하기 위해서 컴포넌트 언마운트시 State를 초기화 한다.
      setChartData({});
    };
  }, []);

  return (
    <div className="chart-root">
      {chartData && (
        <Line
          data={chartData}
          options={{
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              title: {
                display: true,
                text: props.chartTitle,
              },
              legend: {
                display: false,
                position: "top",
              },
              tooltip: {
                callbacks: {
                  label: function(context: TooltipItem<'line'>) {
                    const value = context.raw as number;
                    return 'watch: ' + convertSecondsToTime(value);
                  }
                }
              }
            },
            interaction: {
              intersect: false,
            },
            scales: {
              x: {
                display: props.displayX,
                title: {
                  display: false,
                  text: props.xName,
                },
              },
              y: {
                display: props.displayY,
                title: {
                  // display: true,
                  text: props.yName,
                },
                beginAtZero: true,
                ticks: {
                  callback: function (value: number | string) {
                    return convertSecondsToTime(value as number)
                  },
                  stepSize: props.yStep
                },
              },
            },
          }}
        />
      )}
    </div>
  );
};

export default LineTimeChart;
