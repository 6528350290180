import { useEffect, useRef, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { saveAs } from "file-saver";

import {
  Box,
  Grid,
  Stack,
  Divider,
  Typography,
  Button,
  Paper,
  TextField,
  Switch,
  Tooltip,
  IconButton,
  CardMedia,
  LinearProgress,
} from "@mui/material";
import ShareIcon from "@mui/icons-material/Share";
import DownloadIcon from "@mui/icons-material/Download";
import PageviewIcon from "@mui/icons-material/Pageview";
import DeleteIcon from "@mui/icons-material/Delete";
import LinkIcon from "@mui/icons-material/Link";
import SaveIcon from "@mui/icons-material/Save";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

import { useInterval } from "../../utils/UseInterval";

import { HttpMainApi } from "../../interface/main-api";

import { userState } from "../../interface/MainInterface";
import LoadingCircle from "../../utils/LoadingCircle";
import Toast from "../../utils/Toast";

interface propsType {
  userState: userState;
}

const Test = (props: propsType) => {
  const code = `<div style="padding:177.78% 0 0 0;position:relative;"><iframe src="https://d3tcxze2o0zrps.cloudfront.net/video/fbb3c0d7-cc0c-42ed-ac8f-baf44206a516?loop" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" style="position:absolute;top:0;left:0;width:100%;height:100%;" title="[콜롬보] 최고급 가방의 기준 콜롬보, 디오네 특별제안"></iframe></div>`;
  const code2 = `<div style="padding:177.78% 0 0 0;position:relative;"><iframe src="https://d3tcxze2o0zrps.cloudfront.net/video/619ec2c7-14f4-4301-913d-ad83c8db44b3?loop" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" style="position:absolute;top:0;left:0;width:100%;height:100%;" title="[베즐리] 크리스마스 케이크! 사전예약 최대혜택1"></iframe></div>`;
  const code3 = `<div style="padding:177.78% 0 0 0;position:relative;"><iframe src="https://d3tcxze2o0zrps.cloudfront.net/video/619ec2c7-14f4-4301-913d-ad83c8db44b3?loop" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" style="position:absolute;top:0;left:0;width:100%;height:100%;" title="[베즐리] 크리스마스 케이크! 사전예약 최대혜택2"></iframe></div>`;
  const code4 = `<div style="padding:177.78% 0 0 0;position:relative;"><iframe src="https://d3tcxze2o0zrps.cloudfront.net/video/619ec2c7-14f4-4301-913d-ad83c8db44b3?loop" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" style="position:absolute;top:0;left:0;width:100%;height:100%;" title="[베즐리] 크리스마스 케이크! 사전예약 최대혜택3"></iframe></div>`;
  const code5 = `<div style="padding:177.78% 0 0 0;position:relative;"><iframe src="https://d3tcxze2o0zrps.cloudfront.net/video/619ec2c7-14f4-4301-913d-ad83c8db44b3?loop" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" style="position:absolute;top:0;left:0;width:100%;height:100%;" title="[베즐리] 크리스마스 케이크! 사전예약 최대혜택4"></iframe></div>`;
  const code6 = `<div style="padding:177.78% 0 0 0;position:relative;"><iframe src="https://d3tcxze2o0zrps.cloudfront.net/video/619ec2c7-14f4-4301-913d-ad83c8db44b3?loop" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" style="position:absolute;top:0;left:0;width:100%;height:100%;" title="[베즐리] 크리스마스 케이크! 사전예약 최대혜택5"></iframe></div>`;
  const code7 = `<div style="padding:177.78% 0 0 0;position:relative;"><iframe src="https://d3tcxze2o0zrps.cloudfront.net/video/be2dd40f-0dcc-4051-bbc0-09a0cf375e2f?loop" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" style="position:absolute;top:0;left:0;width:100%;height:100%;" title="레고"></iframe></div>`;
  const code8 = `<div style="padding:177.78% 0 0 0;position:relative;"><iframe src="https://d3tcxze2o0zrps.cloudfront.net/video/eeb39620-9d08-4539-a5a6-e99611e9829e?loop" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" style="position:absolute;top:0;left:0;width:100%;height:100%;" title="저는 지금 유럽에 있습니다.(4K 세로영상_유럽 편)"></iframe></div>`;

  return (
    <>
      <Box sx={{ p: 0, height: "100%", backgroundColor: "#eef1f4" }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={3}>
            <Box dangerouslySetInnerHTML={{ __html: code }} />
          </Grid>
          {/* <Grid item xs={12} sm={3}>
            <Box dangerouslySetInnerHTML={{ __html: code2 }} />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Box dangerouslySetInnerHTML={{ __html: code3 }} />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Box dangerouslySetInnerHTML={{ __html: code4 }} />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Box dangerouslySetInnerHTML={{ __html: code5 }} />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Box dangerouslySetInnerHTML={{ __html: code6 }} />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Box dangerouslySetInnerHTML={{ __html: code7 }} />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Box dangerouslySetInnerHTML={{ __html: code8 }} />
          </Grid> */}
        </Grid>
      </Box>
    </>
  );
};

export default Test;
