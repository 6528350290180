import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Box, Grid, Stack, Divider, Typography, Button, TextField } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";

import { HttpMainApi } from "../../interface/main-api";

import { userState } from "../../interface/MainInterface";
import LoadingCircle from "../../utils/LoadingCircle";
import Toast from "../../utils/Toast";

import CardVideo from "./CardVideo";

interface propsType {
  userState: userState;
  openUploadVideo: any;
}

const mainApi = new HttpMainApi();

const MngVideo = (props: propsType) => {
  const toastRef: any = useRef();
  const [isLoading, setIsLoading] = useState(false);

  const [videoList, setVideoList] = useState<any>([]);
  const [lastKey, setLastKey] = useState("");

  const [searchVideoKey, setSearchVideoKey] = useState<string>("");

  useEffect(() => {
    get_my_video_list();
    return () => {
      setIsLoading(false);
      setVideoList([]);
      setSearchVideoKey("");
    };
  }, []);

  const get_my_video_list = async () => {
    const param: any = {
      command: "get_my_video_list",
      user_id: props.userState.id,
      search_video_key: searchVideoKey,
      last_eval_key: lastKey,
      sortForward: false,
    };

    setIsLoading(true);
    setVideoList([]);
    const res = await mainApi.post(param);
    if (res.code === "200") {
      if (lastKey === "") {
        setVideoList(res.response.my_video_list);
      } else {
        // more
        let newVideoList = [...videoList].concat(res.response.my_video_list);
        setVideoList(newVideoList);
      }

      setLastKey(res.response.last_eval_key);
    } else {
      console.error(res.response.error_msg);
      toastRef.current?.toast("" + res.response.error_msg, "error", 4000, { vertical: "top", horizontal: "center" });
    }
    setIsLoading(false);
  };

  return (
    <>
      <Box sx={{ p: 0, height: "100%" }}>
        <Stack direction={"column"} spacing={0}>
          <Box className="my-account-header-root">
            <Typography variant="h5" gutterBottom className="my-account-header-title">
              나의 비디오
            </Typography>
            <Box sx={{ width: "50%", display: "flex", flexDirection: "row", gap: "10px" }}>
              <TextField
                id="searchVideoKey"
                value={searchVideoKey}
                placeholder="검색할 비디오키를 입력하세요."
                variant="outlined"
                autoFocus
                fullWidth
                type="search"
                autoComplete="off"
                inputProps={{ enterKeyHint: "Enter", style: { padding: "12px 16px 10px 16px" } }}
                onChange={(e) => {
                  setSearchVideoKey(e.target.value);
                }}
                onKeyUp={(e) => {
                  if (e.key === "Enter") {
                    get_my_video_list();
                  }
                }}
              />
              <Button
                variant="contained"
                onClick={get_my_video_list}
                sx={{ width: "200px" }}
                startIcon={<SearchIcon />}
                disabled={!props.userState.isPermission || props.userState.isPermission === undefined}
              >
                검색
              </Button>
            </Box>
          </Box>
          <Divider />
          <Box className="my-account-content-root">
            <Stack direction={"column"} spacing={2}>
              <Box className="apikey-content-header">
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <Typography gutterBottom className="my-account-content-sub-title">
                        본인만의 비디오를 업로드하고 마음껏 공유하세요.
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Box sx={{ display: "flex", width: "100%", justifyContent: "flex-end" }}>
                      <Button
                        variant="contained"
                        onClick={() => {
                          props.openUploadVideo();
                        }}
                        startIcon={<AddIcon />}
                        disabled={!props.userState.isPermission || props.userState.isPermission === undefined}
                      >
                        Upload Video
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                  {videoList.map((videoInfo: any, index: number) => (
                    <Grid key={`myVideo-${index}`} item xs={12} sm={12} md={6} lg={6} xl={3}>
                      <CardVideo
                        userState={props.userState}
                        videoInfo={videoInfo}
                        callback={(payload: any) => {
                          if (payload.command === "delete_video_finish") {
                            toastRef.current?.toast("비디오를 삭제했습니다", "success", 4000, { vertical: "top", horizontal: "center" });
                            get_my_video_list();
                          }
                        }}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Box>
              {lastKey !== "" && (
                <Box>
                  <Button fullWidth variant="contained" onClick={get_my_video_list}>
                    more
                  </Button>
                </Box>
              )}
            </Stack>
          </Box>
        </Stack>
      </Box>
      <LoadingCircle loading={isLoading} />
      <Toast ref={toastRef} />
    </>
  );
};

export default MngVideo;
