import { API, Auth } from "aws-amplify";

export abstract class ApiBase {
  protected apiName: string;
  protected urlPath: string;
  protected authToken: string | undefined;
  protected accessToken: string | undefined;

  abstract onApiName(): string;
  abstract onUrlPath(): string;

  constructor() {
    this.apiName = this.onApiName();
    this.urlPath = this.onUrlPath();
    this.authToken = undefined;
    this.accessToken = undefined;
  }

  protected async getToken() {
    try {
      const session = await Auth.currentSession();
      this.authToken = `Bearer ${session.getIdToken()?.getJwtToken()}`;
    } catch (error) {
      return undefined;
    }

    return this.authToken;
  }

  protected async getAcsToken() {
    try {
      const session = await Auth.currentSession();
      this.accessToken = session.getAccessToken()?.getJwtToken();
    } catch (error) {
      return undefined;
    }

    return this.accessToken;
  }

  protected async requestPost(requestBody: any): Promise<any> {
    let header: any = { Authorization: "abcd" };
    let authToken: any;
    let acsToken: any;

    if (this.apiName === "MainApi") {
      authToken = await this.getToken();
      acsToken = await this.getAcsToken();
      if (authToken !== undefined) {
        header = {
          Authorization: authToken,
        };
      }
    }

    requestBody.access_token = acsToken;

    const request = {
      headers: header,
      body: requestBody,
    };
    try {
      const response = await API.post(this.apiName, this.urlPath, request);
      return response;
    } catch (error) {
      return { code: "500", response: { error_msg: error } };
    }
  }
}
