import { useCallback, useEffect, useRef, useState, forwardRef, useImperativeHandle } from "react";

import { Box, Stack, Modal, Typography, TextField, Button, IconButton, Tooltip } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";

import Toast from "../../utils/Toast";
import LoadingCircle from "../../utils/LoadingCircle";

import { userState } from "../../interface/MainInterface";
import { HttpMainApi } from "../../interface/main-api";

interface propsType {
  userState: userState;
  callback: any;
}

const mainApi = new HttpMainApi();

const PopMakePlaylist = (props: propsType, ref: any) => {
  const toastRef: any = useRef();
  const titleRef: any = useRef();

  const [openModal, setOpenModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [title, setTitle] = useState("");

  useEffect(() => {
    return () => {
      setOpenModal(false);
      setTitle("");
    };
  }, []);

  const create_short_play_list = async () => {
    if (title === "" || title === undefined) {
      toastRef.current?.toast("플레이리스트의 제목을 입력하세요.", "error", 4000, { vertical: "top", horizontal: "center" });
      titleRef.current.focus();
      return;
    }

    const param: any = {
      command: "create_short_play_list",
      short_info: {
        title: title,
      },
      user_info: {
        id: props.userState.id,
      },
    };

    setIsLoading(true);
    const res = await mainApi.post(param);
    if (res.code === "200") {
      close();
      props.callback({ command: "create_short_list_complete", shortKey: res.response.short_key });
    } else {
      console.error(res.response.error_msg);
      toastRef.current?.toast("알수없는 오류가 발생했습니다.", "error", 4000, { vertical: "top", horizontal: "center" });
    }

    setIsLoading(false);
  };

  const open = useCallback(() => {
    setOpenModal(true);
  }, []);

  const close = () => {
    setTitle("");
    setOpenModal(false);
  };

  // 부모 Component에서 접근 가능하도록 함수 전달.
  useImperativeHandle(ref, () => ({
    open,
  }));

  return (
    <>
      <Modal
        open={openModal}
        onClose={() => {
          setOpenModal(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: { xs: "80%", md: "50%", lg: "40%" },
            maxWidth: "90%",
            maxHeight: "90%",
            bgcolor: "background.paper",
            border: "0px solid #000",
            borderRadius: "1rem",
            boxShadow: 24,
            p: 3,
          }}
        >
          <Stack direction="column" spacing={2}>
            <Stack direction="row" spacing={2} sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography gutterBottom sx={{ lineHeight: "40px", fontWeight: "800 !important" }}>
                숏폼 플레이리스트 만들기
              </Typography>
              <Tooltip title="창닫기">
                <IconButton aria-label="close" onClick={close}>
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            </Stack>

            <TextField
              inputRef={titleRef}
              id="txtTitle"
              value={title}
              label="제목"
              placeholder="플레이리스트의 제목을 입력하세요."
              autoFocus
              fullWidth
              onChange={(e) => {
                setTitle(e.target.value);
              }}
              type="search"
              autoComplete="off"
              inputProps={{ enterKeyHint: "Enter" }}
            />

            <Button variant="contained" onClick={create_short_play_list} startIcon={<SaveIcon />} disabled={title === ""}>
              Save
            </Button>
          </Stack>
        </Box>
      </Modal>
      <Toast ref={toastRef} />
      <LoadingCircle loading={isLoading} />
    </>
  );
};

export default forwardRef(PopMakePlaylist);
