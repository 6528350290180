import { useEffect, useRef, useState } from "react";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import { TableContainer, Table, TableBody, TableRow, TableCell, Tooltip, IconButton } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Delete from "@mui/icons-material/Delete";
import KeyIcon from "@mui/icons-material/Key";

import { HttpMainApi } from "../../interface/main-api";

import { userState } from "../../interface/MainInterface";
import LoadingCircle from "../../utils/LoadingCircle";
import Toast from "../../utils/Toast";

import PopAdminCreateUser from "./PopAdminCreateUser";

interface propsType {
  userState: userState;
}

const mainApi = new HttpMainApi();

const MngUser = (props: propsType) => {
  const toastRef: any = useRef();
  const popAdminCreateUserRef: any = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [userList, setUserList] = useState<any>([]);

  useEffect(() => {
    get_user_list();
    return () => {
      setIsLoading(false);
      setUserList([]);
    };
  }, []);

  const get_user_list = async () => {
    const param: any = {
      command: "get_user_list",
      search_conditions: {
        user_id: "",
        user_name: "",
      },
    };

    setIsLoading(true);
    const res = await mainApi.post(param);
    if (res.code === "200") {
      setUserList(res.response.user_list);
    } else {
      console.error(res.response.error_msg);
      toastRef.current?.toast("" + res.response.error_msg, "error", 4000, { vertical: "top", horizontal: "center" });
    }
    setIsLoading(false);
  };

  const add_user = async () => {
    popAdminCreateUserRef.current.open();
  };

  const set_permission_info = async (addPermission: boolean, userInfo: any) => {
    // 수정권한 확인
    if (!props.userState.isAdmin) {
      toastRef.current?.toast("권한부여 권한이 없습니다.", "error", 4000, { vertical: "top", horizontal: "center" });
      return;
    }
    let comfirmText = "해당 사용자에게 시스템 사용 권한을 허가하시겠습니까?";
    if (!addPermission) {
      comfirmText = "해당 사용자로부터 시스템 사용 권한을 해제하시겠습니까?";
    }

    if (!window.confirm(comfirmText)) return;

    setIsLoading(true);
    const param: any = {
      command: "set_permission_info",
      user_info: {
        pk: userInfo.pk,
        sk: userInfo.sk,
        is_permission: addPermission,
      },
      manager_info: {
        id: props.userState.id,
      },
    };

    const res = await mainApi.post(param);
    if (res.code === "200") {
      get_user_list();
    } else {
      console.error(res.response.error_msg);
      toastRef.current?.toast("" + res.response.error_msg, "error", 4000, { vertical: "top", horizontal: "center" });
    }
    setIsLoading(false);
  };

  const set_admin_info = async (addAdmin: boolean, userInfo: any) => {
    // 수정권한 확인
    if (!props.userState.isAdmin) {
      toastRef.current?.toast("권한부여 권한이 없습니다.", "error", 4000, { vertical: "top", horizontal: "center" });
      return;
    }
    let comfirmText = "해당 사용자에게 어드민 권한을 부여하시겠습니까?";
    if (!addAdmin) {
      comfirmText = "해당 사용자로부터 어드민 권한을 해제하시겠습니까?";
    }

    if (!window.confirm(comfirmText)) return;

    setIsLoading(true);
    const param: any = {
      command: "set_admin_info",
      user_info: {
        pk: userInfo.pk,
        sk: userInfo.sk,
        is_admin: addAdmin,
      },
      manager_info: {
        id: props.userState.id,
      },
    };

    const res = await mainApi.post(param);
    if (res.code === "200") {
      get_user_list();
    } else {
      console.error(res.response.error_msg);
      toastRef.current?.toast("" + res.response.error_msg, "error", 4000, { vertical: "top", horizontal: "center" });
    }
    setIsLoading(false);
  };

  const set_super_admin_info = async (addAdmin: boolean, userInfo: any) => {
    // 수정권한 확인
    if (!props.userState.isSuperAdmin) {
      toastRef.current?.toast("권한부여 권한이 없습니다.", "error", 4000, { vertical: "top", horizontal: "center" });
      return;
    }
    let comfirmText = "해당 사용자에게 슈퍼어드민 권한을 부여하시겠습니까?";
    if (!addAdmin) {
      comfirmText = "해당 사용자로부터 슈퍼어드민 권한을 해제하시겠습니까?";
    }

    if (!window.confirm(comfirmText)) return;

    setIsLoading(true);
    const param: any = {
      command: "set_super_admin_info",
      user_info: {
        pk: userInfo.pk,
        sk: userInfo.sk,
        is_super_admin: addAdmin,
      },
      manager_info: {
        id: props.userState.id,
      },
    };

    const res = await mainApi.post(param);
    if (res.code === "200") {
      get_user_list();
    } else {
      console.error(res.response.error_msg);
      toastRef.current?.toast("" + res.response.error_msg, "error", 4000, { vertical: "top", horizontal: "center" });
    }
    setIsLoading(false);
  };

  const delete_user = async (userInfo: any) => {
    // 수정권한 확인
    if (!props.userState.isSuperAdmin) {
      toastRef.current?.toast("권한부여 권한이 없습니다.", "error", 4000, { vertical: "top", horizontal: "center" });
      return;
    }
    let comfirmText = "해당 사용자를 삭제하시겠습니까?삭제한 데이터는 복구할 수 없습니다.";
    if (!window.confirm(comfirmText)) return;

    setIsLoading(true);
    const param: any = {
      command: "delete_user",
      user_info: {
        pk: userInfo.pk,
        sk: userInfo.sk,
      },
      manager_info: {
        id: props.userState.id,
      },
    };

    const res = await mainApi.post(param);
    if (res.code === "200") {
      get_user_list();
    } else {
      console.error(res.response.error_msg);
      toastRef.current?.toast("" + res.response.error_msg, "error", 4000, { vertical: "top", horizontal: "center" });
    }
    setIsLoading(false);
  };

  const reset_user_password = async (userInfo: any) => {
    if (!window.confirm("해당 사용자의 패스워드를 초기화합니다.")) return;

    const param: any = {
      command: "reset_user_password",
      user_info: {
        pk: userInfo.pk,
        sk: userInfo.sk,
      },
      manager_info: {
        id: props.userState.id,
      },
    };

    const res = await mainApi.post(param);
    if (res.code === "200") {
      toastRef.current?.toast(res.response.msg, "success", 4000, {
        vertical: "top",
        horizontal: "center",
      });
    } else {
      console.error(res.response.error_msg);
      toastRef.current?.toast("" + res.response.error_msg, "error", 4000, { vertical: "top", horizontal: "center" });
    }
    setIsLoading(false);
  };

  return (
    <>
      <Box sx={{ p: 0, height: "100%" }}>
        <Stack direction={"column"} spacing={0}>
          <Box className="my-account-header-root">
            <Typography variant="h5" gutterBottom className="my-account-header-title">
              사용자 관리
            </Typography>
          </Box>
          <Divider />
          <Box className="my-account-content-root">
            <Box className="apikey-content-header">
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography gutterBottom className="my-account-content-sub-title">
                  시스템 사용자의 권한을 관리합니다.
                </Typography>
              </Box>
              <Box sx={{ display: "flex" }}>
                <Stack direction={"row"} spacing={2}>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      add_user();
                    }}
                  >
                    사용자 추가
                  </Button>
                  <Button
                    variant="contained"
                    onClick={() => {
                      get_user_list();
                    }}
                  >
                    검색
                  </Button>
                </Stack>
              </Box>
            </Box>
            <Box
              sx={{
                p: 1,
              }}
            >
              <TableContainer
                component={Paper}
                elevation={5}
                sx={{
                  maxHeight: "630px",
                  overflowY: "auto",
                }}
              >
                <Table className="data-table">
                  <TableBody>
                    {userList.map((userInfo: any, index: number) => (
                      <TableRow key={userInfo.sk} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                        <TableCell component="th" scope="row">
                          {userInfo.sk}{" "}
                        </TableCell>
                        <TableCell scope="row">{userInfo.name}</TableCell>
                        <TableCell scope="row">{userInfo.company}</TableCell>
                        <TableCell scope="row">
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={userInfo.is_permission === undefined ? false : userInfo.is_permission}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                  if (props.userState.isSuperAdmin || props.userState.isAdmin)
                                    set_permission_info(e.target.checked, userInfo);
                                }}
                              />
                            }
                            label="사용허가"
                          />
                        </TableCell>
                        <TableCell scope="row">
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={userInfo.is_admin === undefined ? false : userInfo.is_admin}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                  if (props.userState.isSuperAdmin) set_admin_info(e.target.checked, userInfo);
                                }}
                              />
                            }
                            label="어드민"
                          />
                        </TableCell>
                        {props.userState.isSuperAdmin && (
                          <TableCell scope="row">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={userInfo.is_super_admin === undefined ? false : userInfo.is_super_admin}
                                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    set_super_admin_info(e.target.checked, userInfo);
                                  }}
                                />
                              }
                              label="슈퍼어드민"
                            />
                          </TableCell>
                        )}
                        <TableCell scope="row" align="right">
                          <IconButton
                            onClick={() => {
                              reset_user_password(userInfo);
                            }}
                            disabled={userInfo.is_super_admin && !props.userState.isSuperAdmin}
                          >
                            <Tooltip title="패스워드 초기화">
                              <KeyIcon
                                className="data-table-img-button"
                                color={userInfo.is_super_admin && !props.userState.isSuperAdmin ? "disabled" : "primary"}
                              />
                            </Tooltip>
                          </IconButton>
                        </TableCell>
                        <TableCell scope="row" align="right">
                          <IconButton
                            onClick={() => {
                              delete_user(userInfo);
                            }}
                            disabled={userInfo.is_super_admin && !props.userState.isSuperAdmin}
                          >
                            <Tooltip title="사용자 삭제">
                              <Delete
                                className="data-table-img-button"
                                color={userInfo.is_super_admin && !props.userState.isSuperAdmin ? "disabled" : "error"}
                              />
                            </Tooltip>
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Box>
        </Stack>
      </Box>
      <LoadingCircle loading={isLoading} />
      <Toast ref={toastRef} />
      <PopAdminCreateUser
        ref={popAdminCreateUserRef}
        userState={props.userState}
        callback={(payload: any) => {
          if (payload.command === "success") get_user_list();
        }}
      />
    </>
  );
};

export default MngUser;
