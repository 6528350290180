import { useCallback, useEffect, useRef, useState, forwardRef, useImperativeHandle } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Box, Grid, Stack, Modal, Typography, Button, TextField, Switch, Tooltip, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CodeIcon from "@mui/icons-material/Code";
import Toast from "../../utils/Toast";
import { userState } from "../../interface/MainInterface";

interface propsType {
  userState: userState;
  videoInfo: any;
  callback: any;
}

const PopEmbedVideo = (props: propsType, ref: any) => {
  const toastRef: any = useRef();

  const [openModal, setOpenModal] = useState(false);
  const [linkUrl, setLinkUrl] = useState("");
  const [code, setCode] = useState("");
  const [ebdCode, setEbdCode] = useState("");

  const [checkedViewTitle, setCheckedViewTitle] = useState(true);
  const [checkedViewControl, setCheckedViewControl] = useState(true);
  const [checkedAutoPlay, setCheckedAutoPlay] = useState(true);
  const [checkedVideoLoop, setCheckedVideoLoop] = useState(false);

  useEffect(() => {
    return () => {
      setOpenModal(false);
      setCode("");
    };
  }, []);

  const open = useCallback(() => {
    setOpenModal(true);
  }, []);

  const close = () => {
    setCode("");
    setOpenModal(false);
  };

  // 플레이어 옵션에 따른 queryPrameter 생성
  useEffect(() => {
    if (props.videoInfo.sk !== undefined) {
      let queryParam = "";
      if (!checkedViewTitle) {
        if (queryParam === "") {
          queryParam = queryParam + "noTitle";
        } else {
          queryParam = queryParam + "&noTitle";
        }
      }
      if (!checkedViewControl) {
        if (queryParam === "") {
          queryParam = queryParam + "noControl";
        } else {
          queryParam = queryParam + "&noControl";
        }
      }
      if (!checkedAutoPlay) {
        if (queryParam === "") {
          queryParam = queryParam + "noAutoPlay";
        } else {
          queryParam = queryParam + "&noAutoPlay";
        }
      }
      if (checkedVideoLoop) {
        if (queryParam === "") {
          queryParam = queryParam + "loop";
        } else {
          queryParam = queryParam + "&loop";
        }
      }

      if (queryParam !== "") {
        queryParam = "?" + queryParam;
        let url = `${process.env.REACT_APP_PLAYER_DOMAIN_NAME}/video/${props.videoInfo.sk}${queryParam}`;
        let code = `<div style="height:100%;position:relative;"><iframe src="${process.env.REACT_APP_PLAYER_DOMAIN_NAME}/video/${props.videoInfo.sk}${queryParam}" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" style="position:absolute;top:0;left:0;width:100%;height:100%;" title="${props.videoInfo.title}"></iframe></div>`;
        let ebdCode = `<div style="padding:${props.videoInfo.video_rate} 0 0 0;position:relative;"><iframe src="${process.env.REACT_APP_PLAYER_DOMAIN_NAME}/video/${props.videoInfo.sk}${queryParam}" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" style="position:absolute;top:0;left:0;width:100%;height:100%;" title="${props.videoInfo.title}"></iframe></div>`;
        setLinkUrl(url);
        setCode(code);
        setEbdCode(ebdCode);
      } else {
        let url = `${process.env.REACT_APP_PLAYER_DOMAIN_NAME}/video/${props.videoInfo.sk}`;
        let code = `<div style="height:100%;position:relative;"><iframe src="${process.env.REACT_APP_PLAYER_DOMAIN_NAME}/video/${props.videoInfo.sk}" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" style="position:absolute;top:0;left:0;width:100%;height:100%;" title="${props.videoInfo.title}"></iframe></div>`;
        let ebdCode = `<div style="padding:${props.videoInfo.video_rate} 0 0 0;position:relative;"><iframe src="${process.env.REACT_APP_PLAYER_DOMAIN_NAME}/video/${props.videoInfo.sk}" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" style="position:absolute;top:0;left:0;width:100%;height:100%;" title="${props.videoInfo.title}"></iframe></div>`;
        setLinkUrl(url);
        setCode(code);
        setEbdCode(ebdCode);
      }
    }
  }, [checkedViewTitle, checkedViewControl, checkedAutoPlay, checkedVideoLoop, props.videoInfo]);

  // 부모 Component에서 접근 가능하도록 함수 전달.
  useImperativeHandle(ref, () => ({
    open,
  }));

  return (
    <>
      <Modal
        open={openModal}
        onClose={() => {
          setOpenModal(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: { xs: "80%", sm: "50%" },
            maxHeight: "90%",
            bgcolor: "background.paper",
            border: "0px solid #000",
            borderRadius: "1rem",
            boxShadow: 24,
            p: 3,
          }}
        >
          <Stack direction="column" spacing={2}>
            <Stack direction="row" spacing={2} sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography gutterBottom sx={{ lineHeight: "40px", fontWeight: "800 !important" }}>
                Share
              </Typography>
              <Tooltip title="창닫기">
                <IconButton aria-label="close" onClick={close}>
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            </Stack>
            <Box sx={{ width: "100%", height: { xs: "48vh", sm: "60vh" } }} dangerouslySetInnerHTML={{ __html: code }} />
            {/* 플레이어 설정 */}
            <Box className="video-detail-info-row">
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4} sx={{ display: "flex", justifyContent: "flex-start" }}>
                  <Stack direction={"column"} spacing={1} sx={{ width: "100%" }}>
                    <Grid container spacing={0}>
                      <Grid item xs={6} sm={12}>
                        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                          <Typography className="video-detail-embed-title">Title</Typography>
                          <Switch
                            checked={checkedViewTitle}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                              setCheckedViewTitle(e.target.checked);
                            }}
                            inputProps={{ "aria-label": "controlled" }}
                          />
                        </Box>
                        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                          <Typography className="video-detail-embed-title">Control</Typography>
                          <Switch
                            checked={checkedViewControl}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                              setCheckedViewControl(e.target.checked);
                            }}
                            inputProps={{ "aria-label": "controlled" }}
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={6} sm={12}>
                        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                          <Typography className="video-detail-embed-title">Auto Play</Typography>
                          <Switch
                            checked={checkedAutoPlay}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                              setCheckedAutoPlay(e.target.checked);
                            }}
                            inputProps={{ "aria-label": "controlled" }}
                          />
                        </Box>
                        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                          <Typography className="video-detail-embed-title">Loop</Typography>
                          <Switch
                            checked={checkedVideoLoop}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                              setCheckedVideoLoop(e.target.checked);
                            }}
                            inputProps={{ "aria-label": "controlled" }}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  </Stack>
                </Grid>
                <Grid item xs={12} sm={8} sx={{ display: "flex", justifyContent: "flex-start" }}>
                  <Box sx={{ width: "100%" }}>
                    <Stack direction={"column"} spacing={2}>
                      <TextField
                        id="txtEmbed"
                        value={ebdCode}
                        fullWidth
                        multiline
                        type="search"
                        minRows={5}
                        maxRows={5}
                        autoComplete="off"
                        sx={{ wordBreak: "break-all" }}
                        inputProps={{ enterKeyHint: "Enter" }}
                      />
                    </Stack>
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Stack direction={"row"} spacing={2}>
              <CopyToClipboard
                text={ebdCode}
                onCopy={() => {
                  toastRef.current?.toast("임베딩 코드를 복사했습니다.", "success", 3000, {
                    vertical: "top",
                    horizontal: "center",
                  });
                }}
              >
                <Button variant="contained" endIcon={<CodeIcon />} disabled={ebdCode === ""} fullWidth>
                  임베딩 코드 복사
                </Button>
              </CopyToClipboard>
              <CopyToClipboard
                text={linkUrl}
                onCopy={() => {
                  toastRef.current?.toast("링크를 복사했습니다.", "success", 3000, {
                    vertical: "top",
                    horizontal: "center",
                  });
                }}
              >
                <Button variant="contained" endIcon={<CodeIcon />} disabled={linkUrl === ""} fullWidth>
                  링크 복사
                </Button>
              </CopyToClipboard>
            </Stack>
          </Stack>
        </Box>
      </Modal>
      <Toast ref={toastRef} />
    </>
  );
};

export default forwardRef(PopEmbedVideo);
