import { useCallback, useEffect, useRef, useState, forwardRef, useImperativeHandle } from "react";
import { Drawer, Box, Grid, Stack, Typography, Button, Tooltip, IconButton, CardMedia } from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";

import Toast from "../../utils/Toast";
import LoadingCircle from "../../utils/LoadingCircle";
import { userState } from "../../interface/MainInterface";

import { HttpMainApi } from "../../interface/main-api";

interface propsType {
  userState: userState;
  shortInfo: any;
  playList: any;
  callback: any;
}

const mainApi = new HttpMainApi();

const DrawerVideoList = (props: propsType, ref: any) => {
  const toastRef: any = useRef();

  const [isLoading, setIsLoading] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);

  const [myVideoList, setMyVideoList] = useState([] as any);
  const [videoList, setVideoList] = useState([] as any);
  const [lastKey, setLastKey] = useState("");

  useEffect(() => {
    get_my_video_list();
    return () => {
      setIsLoading(false);
      setOpenDrawer(false);
      setMyVideoList([]);
      setVideoList([]);
    };
  }, []);

  const get_my_video_list = async () => {
    const param: any = {
      command: "get_my_video_list",
      user_id: props.userState.id,
      last_eval_key: lastKey,
      sortForward: false,
    };

    setIsLoading(true);
    setMyVideoList([]);
    const res = await mainApi.post(param);
    if (res.code === "200") {
      if (lastKey === "") {
        setMyVideoList(res.response.my_video_list);
      } else {
        // more
        let newVideoList = [...videoList].concat(res.response.my_video_list);
        setMyVideoList(newVideoList);
      }

      setLastKey(res.response.last_eval_key);
    } else {
      console.error(res.response.error_msg);
      toastRef.current?.toast("" + res.response.error_msg, "error", 4000, { vertical: "top", horizontal: "center" });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    let newVideoList: any = [];
    for (const videoInfo of myVideoList) {
      if (props.playList.indexOf(videoInfo.sk) === -1) {
        newVideoList.push(videoInfo);
      }
    }
    setVideoList(newVideoList);
  }, [myVideoList, props.playList]);

  const add_video_to_short = async (videoInfo: any) => {
    const param: any = {
      command: "add_video_to_short",
      short_key: props.shortInfo.sk,
      video_key: videoInfo.sk,
      priority: true,
    };

    setIsLoading(true);
    const res = await mainApi.post(param);
    if (res.code === "200") {
      props.callback({ command: "finish_add_video" });
    } else {
      console.error(res.response.error_msg);
      toastRef.current?.toast("" + res.response.error_msg, "error", 4000, { vertical: "top", horizontal: "center" });
    }
    setIsLoading(false);
  };

  const open = useCallback(() => {
    setOpenDrawer(true);
  }, []);

  const close = () => {
    setOpenDrawer(false);
  };

  // 부모 Component에서 접근 가능하도록 함수 전달.
  useImperativeHandle(ref, () => ({
    open,
  }));

  return (
    <>
      <Drawer anchor={"right"} open={openDrawer} onClose={close} PaperProps={{ sx: { width: "50%", height: "100%", p: 3 } }}>
        <Stack direction={"column"} spacing={4}>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
              <Typography className="video-detail-info-title">{`Select videos`}</Typography>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Tooltip title="창닫기">
                <IconButton aria-label="close" onClick={close}>
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
          <Box sx={{ width: "100%" }}>
            <Grid container spacing={2}>
              {videoList.map((videoInfo: any, index: number) => (
                <Grid key={`myVideo-${index}`} item xs={12} sm={12} md={6} lg={6} xl={3}>
                  <Button
                    onClick={() => {
                      add_video_to_short(videoInfo);
                    }}
                  >
                    <Box sx={{ position: "relative" }}>
                      <CardMedia
                        component="img"
                        image={videoInfo.thumb === "custom" ? videoInfo.custom_tumbnail : videoInfo.thumbnail_url}
                        sx={{ objectFit: "contain" }}
                      />
                      <Box sx={{ position: "absolute", top: "3px", left: "3px", p: 1 }}>
                        <Typography sx={{ color: "#FFFFFF", fontWeight: "700 !important", textAlign: "left" }}>
                          {videoInfo.display_title}
                        </Typography>
                      </Box>
                    </Box>
                  </Button>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Stack>
      </Drawer>
      <Toast ref={toastRef} />
      <LoadingCircle loading={isLoading} />
    </>
  );
};

export default forwardRef(DrawerVideoList);
