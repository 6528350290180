import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import dayjs, { Dayjs } from "dayjs";

import { Box, Stack, Divider, Typography, FormControl, Select, MenuItem, TextField, Button, Pagination, InputLabel } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  useGridApiRef,
  useGridSelector,
  gridPageSelector,
  gridPageCountSelector,
  GridRowSelectionModel,
  GridPaginationModel,
  GridRenderCellParams,
} from "@mui/x-data-grid";

import { HttpMainApi } from "../../interface/main-api";
import { HttpStatisticApi } from "../../interface/statistic-api";

import { userState } from "../../interface/MainInterface";
import LoadingCircle from "../../utils/LoadingCircle";
import { useWindowSize } from "../../utils/useWindowSize";
import { CommonUtils } from "../../utils/common_utils";

interface propsType {
  userState: userState;
}

interface apiParam {
  create_time: string;
  short_key: string;
  display_title: string;
  display_subtitle: string;
  search_from: string;
  search_to: string;
}

const mainApi = new HttpMainApi();
const statisticApi = new HttpStatisticApi();

const pageSize: number = 10;

const MngVideoList = (props: propsType) => {
  const cUtils = new CommonUtils();
  const gridApi = useGridApiRef();
  const navigate = useNavigate();
  const toastRef: any = useRef();
  const windowSize = useWindowSize();

  const [srchDate, setSrchDate] = useState<Dayjs | null>();

  const [gridHeight, setGridHeight] = useState(608);
  const [shortSearchLastKey, setShortSearchLastKey] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [myVideoList, setMyVideoList] = useState([] as any);
  const [shortList, setShortList] = useState<any>([]);
  const [selectShort, setSelectShort] = useState("숏폼선택");
  const [displayTitle, setDisplayTitle] = useState("");
  const [displaySubTitle, setDisplaySubTitle] = useState("");

  const [selectedRow, setSelectedRow] = useState<GridRowSelectionModel>([]);
  const [selectedPage, setSelectedPage] = useState<GridPaginationModel>({ pageSize: 10, page: 0 });

  const [fromDate, setFromDate] = useState<Dayjs | null>(dayjs().startOf("week"));
  const [toDate, setToDate] = useState<Dayjs | null>(dayjs().endOf("week"));

  useEffect(() => {
    get_my_video_list_with_statistics();
    get_my_short_list();
    return () => {
      // 메모리 누수를 방지하기 위해서 컴포넌트 언마운트시 State를 초기화 한다.
      setSrchDate(null);
      setGridHeight(608);
      setShortSearchLastKey("");
      setIsLoading(false);
      setMyVideoList([]);
      setShortList([]);
      setSelectShort("숏폼선택");
      setDisplayTitle("");
      setDisplaySubTitle("");
      setSelectedRow([]);
      setSelectedPage({ pageSize: 10, page: 0 });
      setFromDate(dayjs().startOf("week"));
      setToDate(dayjs().endOf("week"));
    };
  }, []);

  useEffect(() => {
    setGridHeight(windowSize.height - 303);
  }, [windowSize]);

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "NO",
      width: 30,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "create_time",
      headerName: "업로드일",
      width: 80,
      headerAlign: "center",
      align: "center",
      renderCell: (param: GridRenderCellParams) => {
        return (
          <>
            <span>{dayjs(param.row.create_time).format("YYYY-MM-DD")}</span>
          </>
        );
      },
    },
    {
      field: "sk",
      headerName: "영상ID KEY",
      width: 320,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "mng_title",
      headerName: "관리타이틀",
      width: 400,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "total_likes",
      headerName: "전체 좋아요 수",
      width: 120,
      headerAlign: "center",
      type: "number",
      align: "center"
    },
    {
      field: "total_views",
      headerName: "전체 VIEW 수",
      width: 120,
      headerAlign: "center",
      type: "number",
      align: "center"
    },
    {
      field: "likes_count",
      headerName: "기간별 좋아요 수",
      width: 120,
      headerAlign: "center",
      type: "number",
      align: "center"
    },
    {
      field: "views_count",
      headerName: "기간별 VIEW 수",
      width: 120,
      headerAlign: "center",
      type: "number",
      align: "center"
    },
    {
      field: "total_watch_time",
      headerName: "전체 시청 시간",
      width: 120,
      headerAlign: "center",
      type: "number",
      align: "center",
      renderCell: (param: GridRenderCellParams) => {
        return (
          <>
            <span>{convertSecondsToTime(param.row.total_watch_time)}</span>
          </>
        );
      },
    },
    {
      field: "range_watch_time",
      headerName: "기간별 시청 시간",
      width: 120,
      headerAlign: "center",
      type: "number",
      align: "center",
      renderCell: (param: GridRenderCellParams) => {
        return (
          <>
            <span>{convertSecondsToTime(param.row.range_watch_time)}</span>
          </>
        );
      },
    },
    {
      field: "display_title",
      headerName: "전시타이틀",
      width: 300,
      headerAlign: "center",
      align: "center"
    },
    {
      field: "display_sub_title",
      headerName: "서브타이틀",
      width: 300,
      headerAlign: "center",
      align: "center"
    }
  ];

  const get_my_video_list_with_statistics = async (
    value: apiParam = {
      create_time: dayjs(srchDate, "YYYYMMDDHHmmss").format("YYYYMMDD"),
      short_key: selectShort,
      display_title: displayTitle,
      display_subtitle: displaySubTitle,
      search_from: dayjs(fromDate).format("YYYYMMDD"),
      search_to: dayjs(toDate).add(1, "day").format("YYYYMMDD"),
    }
  ) => {
    if (value.create_time === "Invalid Date") value.create_time = "";
    if (value.short_key === "숏폼선택") value.short_key = "";

    const param: any = {
      command: "get_my_video_list_with_statistics",
      user_id: props.userState.id,
      sortForward: false,
      statisticsParam: value,
    };

    setIsLoading(true);
    setMyVideoList([]);
    const res = await statisticApi.post(param);
    if (res.code === "200") {
      setMyVideoList(res.response.my_video_list.map((row: any, index: any) => ({ ...row, id: index + 1 })));
    }
    setIsLoading(false);
  };

  const get_my_short_list = async () => {
    const param: any = {
      command: "get_my_short_list",
      user_id: props.userState.id,
      last_eval_key: shortSearchLastKey,
      sortForward: false,
    };

    // setIsLoading(true);
    setShortList([]);
    const res = await mainApi.post(param);
    if (res.code === "200") {
      if (shortSearchLastKey === "") {
        setShortList(res.response.my_short_list);
      } else {
        // more
        let newVideoList = [...shortList].concat(res.response.my_short_list);
        setShortList(newVideoList);
      }
      setShortSearchLastKey(res.response.last_eval_key);
    } else {
      console.error(res.response.error_msg);
      toastRef.current?.toast("" + res.response.error_msg, "error", 4000, { vertical: "top", horizontal: "center" });
    }
    // setIsLoading(false);
  };

  const handleSrchDateChange = (newValue: Dayjs | null) => {
    setSrchDate(newValue);
    get_my_video_list_with_statistics({
      create_time: dayjs(newValue, "YYYYMMDDHHmmss").format("YYYYMMDD"),
      short_key: selectShort,
      display_title: "",
      display_subtitle: "",
      search_from: dayjs(fromDate).format("YYYYMMDD"),
      search_to: dayjs(toDate).add(1, "day").format("YYYYMMDD"),
    });
  };

  const handleSelectShortChange = (newValue: string) => {
    if (newValue === "more") {
      get_my_short_list();
    } else {
      setSelectShort(newValue);
      get_my_video_list_with_statistics({
        create_time: dayjs(srchDate, "YYYYMMDDHHmmss").format("YYYYMMDD"),
        short_key: newValue,
        display_title: "",
        display_subtitle: "",
        search_from: dayjs(fromDate).format("YYYYMMDD"),
        search_to: dayjs(toDate).add(1, "day").format("YYYYMMDD"),
      });
    }
  };

  const getVideoList = () => {
    get_my_video_list_with_statistics({
      create_time: dayjs(srchDate, "YYYYMMDDHHmmss").format("YYYYMMDD"),
      short_key: selectShort,
      display_title: displayTitle,
      display_subtitle: displaySubTitle,
      search_from: dayjs(fromDate).format("YYYYMMDD"),
      search_to: dayjs(toDate).add(1, "day").format("YYYYMMDD"),
    });
  };

  const evtGridDblClick = useCallback(
    (param: GridCellParams) => {
      const url = `/detail/${param.row.sk}`;
      navigate(url);
    },
    [navigate]
  );

  const convertSecondsToTime = (seconds: number) => {
    const addZero = (num: number) => {
      return ((num < 10) ? '0' : '') + num
    }

    if(seconds < 61) {
      return '00:' + addZero(seconds)
    }

    const hours = Math.floor(seconds / 3600)
    const mins = Math.floor((seconds - hours * 3600) / 60)
    const secs = seconds - hours * 3600 - mins * 60
    
    return addZero(hours) + ':' + addZero(mins) + ':' + addZero(secs)

  }

  const downloadExcel = () => {
    let header: any = [
      [
        "NO",
        "업로드일",
        "영상ID KEY",
        "전시타이틀",
        "서브타이틀",
        "좋아요 수",
        "VIEW 수",
        `기간별 좋아요 수(${dayjs(fromDate).format("YYYYMMDD")} ~ ${dayjs(toDate).format("YYYYMMDD")})`,
        `기간별 VIEW 수(${dayjs(fromDate).format("YYYYMMDD")} ~ ${dayjs(toDate).format("YYYYMMDD")})`,
        `전체 시청 시간(${dayjs(fromDate).format("YYYYMMDD")} ~ ${dayjs(toDate).format("YYYYMMDD")})`,
        `기간별 시청 시간(${dayjs(fromDate).format("YYYYMMDD")} ~ ${dayjs(toDate).format("YYYYMMDD")})`,
      ],
    ];
    let index = 1;
    for (const data of myVideoList) {
      const detail = [
        index,
        dayjs(data.create_time, "YYYYMMDDHHmmss").format("YYYY-MM-DD"),
        data.sk,
        data.display_title,
        data.display_sub_title,
        data.total_likes,
        data.total_views,
        data.likes_count,
        data.views_count,
        convertSecondsToTime(data.total_watch_time),
        convertSecondsToTime(data.range_watch_time)
      ];
      header = [...header, detail];
      index += 1;
    }
    const colWidth = [
      { wpx: 40 },
      { wpx: 80 },
      { wpx: 240 },
      { wpx: 300 },
      { wpx: 250 },
      { wpx: 50 },
      { wpx: 50 },
      { wpx: 200 },
      { wpx: 200 },
      { wpx: 200 },
      { wpx: 200 },
    ];
    const sheetName = "통계내역";

    const fileName = dayjs().format("YYYY-MM-DD HHmm") + "_영상 리스트_.xlsx";
    cUtils.downloadExcel(header, colWidth, sheetName, fileName);
  };

  function CustomPagination() {
    const page = useGridSelector(gridApi, gridPageSelector);
    const pageCount = useGridSelector(gridApi, gridPageCountSelector);
    return (
      <Box className="tenants-list-pagenation">
        <Pagination
          color="primary"
          shape="rounded"
          count={pageCount === 0 ? 1 : pageCount}
          page={page + 1}
          siblingCount={10}
          onChange={(e, v) => gridApi.current.setPage(v - 1)}
        ></Pagination>
      </Box>
    );
  }

  return (
    <>
      <Box sx={{ p: 0, height: "100%" }}>
        <Stack direction={"column"} spacing={0}>
          <Box className="my-account-header-root">
            <Typography variant="h5" gutterBottom className="my-account-header-title">
              영상 리스트
            </Typography>
          </Box>
          <Divider />
          <Box className="my-account-content-root">
            <Stack direction={"column"} spacing={2}>
              <Box className="apikey-content-header" sx={{ pt: 2 }}>
                <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                  <Box>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={["DatePicker"]}>
                        <DatePicker
                          label="업로드일"
                          showDaysOutsideCurrentMonth
                          format="YYYY-MM-DD"
                          value={srchDate}
                          onChange={handleSrchDateChange}
                          sx={{ width: "160px", minWidth: "160px !important" }}
                          slotProps={{
                            textField: { size: "small" },
                          }}
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                  </Box>
                  <Box sx={{ pt: "9px" }}>
                    <FormControl>
                      <InputLabel>숏폼그룹</InputLabel>
                      <Select
                        labelId="short-select-label"
                        id="short-select"
                        value={selectShort}
                        label="숏폼그룹"
                        onChange={(e) => handleSelectShortChange(e.target.value)}
                        size="small"
                      >
                        <MenuItem value={"숏폼선택"}>{"숏폼선택"}</MenuItem>
                        {shortList.map((short: any) => (
                          <MenuItem key={short.sk} value={short.sk}>
                            {short.title}
                          </MenuItem>
                        ))}
                        {shortSearchLastKey !== "" && (
                          <MenuItem key={`more-button`} value={"more"}>
                            {`more`}
                          </MenuItem>
                        )}
                      </Select>
                    </FormControl>
                  </Box>
                  <Box sx={{ pt: "9px" }}>
                    <Stack direction={"row"} spacing={2}>
                      <TextField
                        id="input-display-title"
                        label="전시타이틀"
                        value={displayTitle}
                        type="search"
                        autoComplete="off"
                        inputProps={{ enterKeyHint: "Enter" }}
                        onChange={(e) => {
                          setDisplayTitle(e.target.value);
                        }}
                        size="small"
                      />
                      <TextField
                        id="input-display-subtitle"
                        label="서브타이틀"
                        value={displaySubTitle}
                        type="search"
                        autoComplete="off"
                        inputProps={{ enterKeyHint: "Enter" }}
                        onChange={(e) => {
                          setDisplaySubTitle(e.target.value);
                        }}
                        size="small"
                      />
                    </Stack>
                  </Box>
                  <Box>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={["DatePicker", "DatePicker"]}>
                        <DatePicker
                          label="통계기간 From"
                          showDaysOutsideCurrentMonth
                          format="YYYY-MM-DD"
                          value={fromDate}
                          onChange={(newValue) => setFromDate(newValue)}
                          sx={{ width: "160px", minWidth: "160px !important" }}
                          slotProps={{
                            textField: { size: "small" },
                          }}
                        />
                        <DatePicker
                          label="통계기간 To"
                          showDaysOutsideCurrentMonth
                          format="YYYY-MM-DD"
                          value={toDate}
                          onChange={(newValue) => setToDate(newValue)}
                          sx={{ width: "160px", minWidth: "160px !important" }}
                          slotProps={{
                            textField: { size: "small" },
                          }}
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                  </Box>
                </Stack>
                <Box sx={{ pt: "10px", minWidth: "200px", display: "flex", flexDirection: "row", gap: "16px", justifyContent: "flex-end" }}>
                  <Button variant="contained" color="primary" onClick={getVideoList}>
                    조회
                  </Button>
                  <Button variant="outlined" color="primary" onClick={downloadExcel} disabled={myVideoList.length === 0}>
                    엑셀
                  </Button>
                </Box>
              </Box>
              <Box sx={{ width: "100%", height: gridHeight }}>
                <DataGrid
                  apiRef={gridApi}
                  rows={myVideoList}
                  columns={columns}
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: pageSize,
                      },
                    },
                    sorting: {
                      sortModel: [
                        {
                          field: "range_num",
                          sort: "asc",
                        },
                      ],
                    },
                  }}
                  slots={{ pagination: CustomPagination }}
                  hideFooterSelectedRowCount
                  getRowId={(row) => row.sk}
                  rowSelectionModel={selectedRow}
                  onRowSelectionModelChange={(newRowSelectionModel) => {
                    setSelectedRow(newRowSelectionModel);
                  }}
                  paginationModel={selectedPage}
                  onPaginationModelChange={(newPagenationModel) => {
                    setSelectedPage(newPagenationModel);
                  }}
                  onCellDoubleClick={evtGridDblClick}
                />
              </Box>
              <Typography>※ 각 항목을 더블클릭하면 비디오 상세페이지로 이동합니다.</Typography>
            </Stack>
          </Box>
        </Stack>
      </Box>
      <LoadingCircle loading={isLoading} />
    </>
  );
};

export default MngVideoList;
