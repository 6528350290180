import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Box, Grid, Stack, Divider, Typography, Button, TextField } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";

import { HttpMainApi } from "../../interface/main-api";

import PopMakePlaylist from "./PopMakePlaylist";
import CardShort from "./CardShort";

import { userState } from "../../interface/MainInterface";
import LoadingCircle from "../../utils/LoadingCircle";
import Toast from "../../utils/Toast";

interface propsType {
  userState: userState;
}

const mainApi = new HttpMainApi();

const MngShort = (props: propsType) => {
  const navigate = useNavigate();
  const toastRef: any = useRef();
  const popMakePlaylistRef: any = useRef();
  const [isLoading, setIsLoading] = useState(false);

  const [shortList, setShortList] = useState<any>([]);
  const [lastKey, setLastKey] = useState("");

  const [searchShortKey, setSearchShortKey] = useState<string>("");

  useEffect(() => {
    get_my_short_list();
    return () => {
      setIsLoading(false);
      setShortList([]);
      setSearchShortKey("");
    };
  }, []);

  const get_my_short_list = async () => {
    const param: any = {
      command: "get_my_short_list",
      user_id: props.userState.id,
      search_short_key: searchShortKey,
      last_eval_key: lastKey,
      sortForward: false,
    };

    setIsLoading(true);
    setShortList([]);
    const res = await mainApi.post(param);
    if (res.code === "200") {
      if (lastKey === "") {
        setShortList(res.response.my_short_list);
      } else {
        // more
        let newVideoList = [...shortList].concat(res.response.my_short_list);
        setShortList(newVideoList);
      }

      setLastKey(res.response.last_eval_key);
    } else {
      console.error(res.response.error_msg);
      toastRef.current?.toast("" + res.response.error_msg, "error", 4000, { vertical: "top", horizontal: "center" });
    }
    setIsLoading(false);
  };

  return (
    <>
      <Box sx={{ p: 0, height: "100%" }}>
        <Stack direction={"column"} spacing={0}>
          <Box className="my-account-header-root">
            <Typography variant="h5" gutterBottom className="my-account-header-title">
              나의 숏폼
            </Typography>
            <Box sx={{ width: "50%", display: "flex", flexDirection: "row", gap: "10px" }}>
              <TextField
                id="searchShortKey"
                value={searchShortKey}
                placeholder="검색할 숏폼키를 입력하세요."
                variant="outlined"
                autoFocus
                fullWidth
                type="search"
                autoComplete="off"
                inputProps={{ enterKeyHint: "Enter", style: { padding: "12px 16px 10px 16px" } }}
                onChange={(e) => {
                  setSearchShortKey(e.target.value);
                }}
                onKeyUp={(e) => {
                  if (e.key === "Enter") {
                    get_my_short_list();
                  }
                }}
              />
              <Button
                variant="contained"
                onClick={get_my_short_list}
                sx={{ width: "200px" }}
                startIcon={<SearchIcon />}
                disabled={!props.userState.isPermission || props.userState.isPermission === undefined}
              >
                검색
              </Button>
            </Box>
          </Box>
          <Divider />
          <Box className="my-account-content-root">
            <Stack direction={"column"} spacing={2}>
              <Box className="apikey-content-header">
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <Typography gutterBottom className="my-account-content-sub-title">
                        숏폼 플레이 리스트를 만들어보세요.
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Box sx={{ display: "flex", width: "100%", justifyContent: "flex-end" }}>
                      <Button
                        variant="contained"
                        onClick={() => {
                          popMakePlaylistRef.current?.open();
                        }}
                        startIcon={<AddIcon />}
                      >
                        Make Playlist
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                  {shortList.map((shortInfo: any, index: number) => (
                    <Grid key={`myShort-${index}`} item xs={12} sm={12} md={6} lg={6} xl={3}>
                      <CardShort userState={props.userState} shortInfo={shortInfo} callback={(payload: any) => {}} />
                    </Grid>
                  ))}
                </Grid>
              </Box>
              {lastKey !== "" && (
                <Box>
                  <Button fullWidth variant="contained" onClick={get_my_short_list}>
                    more
                  </Button>
                </Box>
              )}
            </Stack>
          </Box>
        </Stack>
      </Box>
      <LoadingCircle loading={isLoading} />
      <Toast ref={toastRef} />
      <PopMakePlaylist
        ref={popMakePlaylistRef}
        userState={props.userState}
        callback={(payload: any) => {
          if (payload.command === "create_short_list_complete") {
            navigate(`/shortDetail/${payload.shortKey}`);
          }
        }}
      />
    </>
  );
};

export default MngShort;
