import { useCallback, useEffect, useRef, useState, forwardRef, useImperativeHandle } from "react";
import dayjs, { Dayjs } from "dayjs";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Drawer, Box, Grid, Stack, Modal, Typography, Button, TextField, Switch, Tooltip, IconButton, Paper } from "@mui/material";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import CodeIcon from "@mui/icons-material/Code";
import Toast from "../../utils/Toast";
import LoadingCircle from "../../utils/LoadingCircle";
import { userState } from "../../interface/MainInterface";
import { HttpStatisticApi } from "../../interface/statistic-api";
import LineChart from "../../utils/chart/LineChart";
import LineTimeChart from "../../utils/chart/LineTimeChart";

interface propsType {
  userState: userState;
  videoInfo: any;
  callback: any;
}

const statisticApi = new HttpStatisticApi();

const DrawerVideoAnalytics = (props: propsType, ref: any) => {
  const toastRef: any = useRef();

  const [isLoading, setIsLoading] = useState(false);

  const [openDrawer, setOpenDrawer] = useState(false);
  const [viewCountsRows, setViewCountsRows] = useState<any>([]);
  const [likeCountsRows, setLikeCountsRows] = useState<any>([]);
  const [watchCountsRows, setWatchCountsRows] = useState<any>([]);
  const [totalViews, setTotalViews] = useState(0);
  const [totalLikes, setTotalLikes] = useState(0);
  const [totalWatches, setTotalWatches] = useState(0);

  const [fromDate, setFromDate] = useState<Dayjs | null>(dayjs().startOf("month"));
  const [toDate, setToDate] = useState<Dayjs | null>(dayjs().endOf("month"));

  const get_statistic_detail = async () => {
    const param: any = {
      command: "get_statistic_detail",
      video_key: props.videoInfo.sk,
      metric: "daily",
      search_from: dayjs(fromDate).format("YYYYMMDD"),
      search_to: dayjs(toDate).add(1, "day").format("YYYYMMDD"),
    };
    setIsLoading(true);
    const res = await statisticApi.post(param);
    if (res.code === "200") {
      const views: any = [];
      const likes: any = [];
      const watches: any = [];
      let tViews = 0;
      let tLikes = 0;
      let tWatches = 0;
      for (const view of res.response.view_list) {
        views.push({ label: view.label, value: view.value });
        tViews = tViews + view.value;
      }
      for (const like of res.response.like_list) {
        likes.push({ label: like.label, value: like.value });
        tLikes = tLikes + like.value;
      }
      for (const watch of res.response.watch_list) {
        watches.push({ label: watch.label, value: watch.value});
        tWatches = tWatches + watch.value;
      }
      setViewCountsRows(views);
      setLikeCountsRows(likes);
      setWatchCountsRows(watches);
      setTotalViews(tViews);
      setTotalLikes(tLikes);
      setTotalWatches(tWatches);
    }
    setIsLoading(false);
  };

  const convertFormat = (input: string) => {
    return dayjs(input).format("YY.MM.DD");
  };

  const convertSecondsToTime = (seconds: number) => {
    const addZero = (num: number) => {
      return ((num < 10) ? '0' : '') + num
    }

    if(seconds < 61) {
      return '00:' + addZero(seconds)
    }

    const hours = Math.floor(seconds / 3600)
    const mins = Math.floor((seconds - hours * 3600) / 60)
    const secs = seconds - hours * 3600 - mins * 60
    
    return addZero(hours) + ':' + addZero(mins) + ':' + addZero(secs)

  }

  useEffect(() => {
    return () => {
      setOpenDrawer(false);
      setViewCountsRows([]);
      setLikeCountsRows([]);
      setWatchCountsRows([]);
    };
  }, []);

  const open = useCallback(() => {
    get_statistic_detail();
    setOpenDrawer(true);
  }, []);

  const close = () => {
    setOpenDrawer(false);
  };

  // 부모 Component에서 접근 가능하도록 함수 전달.
  useImperativeHandle(ref, () => ({
    open,
  }));

  return (
    <>
      <Drawer anchor={"right"} open={openDrawer} onClose={close} PaperProps={{ sx: { height: "100%", p: 3}}}>
        <Stack direction={"column"} spacing={4} >
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
              <Typography className="video-detail-info-title">Analytics</Typography>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Tooltip title="창닫기">
                <IconButton aria-label="close" onClick={close}>
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
          <Box>
            <Stack direction={"row"} spacing={2}>
              <LocalizationProvider dateAdapter={AdapterDayjs} dateFormats={{ monthShort: "M" }}>
                <DemoContainer components={["DatePicker", "DatePicker"]}>
                  <DatePicker
                    label="From"
                    showDaysOutsideCurrentMonth
                    format="YYYY-MM-DD"
                    value={fromDate}
                    onChange={(newValue) => setFromDate(newValue)}
                  />
                  <DatePicker
                    label="To"
                    showDaysOutsideCurrentMonth
                    format="YYYY-MM-DD"
                    value={toDate}
                    onChange={(newValue) => setToDate(newValue)}
                  />
                </DemoContainer>
              </LocalizationProvider>
              <Button
                variant="contained"
                size="small"
                color="primary"
                onClick={() => {
                  get_statistic_detail();
                }}
                sx={{ borderRadius: "7px", width: "54px", height: "54px", mt: "9px !important" }}
              >
                <SearchIcon />
              </Button>
            </Stack>
          </Box>
          <Box sx={{ width: "100%", height: "300px" }}>
            <Paper sx={{ width: "100%", height: "100%", p: 3, boxSizing: "border-box" }} elevation={4}>
              <LineChart
                dataRows={viewCountsRows}
                chartTitle={`views(${totalViews})`}
                labelTitle={"view"}
                xName={"date"}
                yName={""}
                lineColor={"#8ad1f2"}
                convertFormat={convertFormat}
                displayX={false}
                displayY={true}
                yStep={10}
              />
            </Paper>
          </Box>
          <Box sx={{ width: "100%", height: "300px" }}>
            <Paper sx={{ width: "100%", height: "100%", p: 3, boxSizing: "border-box" }} elevation={4}>
              <LineChart
                dataRows={likeCountsRows}
                chartTitle={`likes(${totalLikes})`}
                labelTitle={"like"}
                xName={"date"}
                yName={""}
                lineColor={"#8ad1f2"}
                convertFormat={convertFormat}
                displayX={false}
                displayY={true}
                yStep={10}
              />
            </Paper>
          </Box>
          <Box sx={{ width: "100%", height: "300px"}}>
            <Paper sx={{ width: "100%", height: "100%", p: 3, boxSizing: "border-box"}} elevation={4}>
              <LineTimeChart
                dataRows={watchCountsRows}
                chartTitle={`watches(${convertSecondsToTime(totalWatches)})`}
                labelTitle={"watch"}
                xName={"date"}
                yName={""}
                lineColor={"#8ad1f2"}
                convertFormat={convertFormat}
                displayX={false}
                displayY={true}
                yStep={60}
              />
            </Paper>
          </Box>
        </Stack>
      </Drawer>
      <Toast ref={toastRef} />
      <LoadingCircle loading={isLoading} />
    </>
  );
};

export default forwardRef(DrawerVideoAnalytics);
